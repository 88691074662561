import errorPage from '../assets/404.svg';
import { Button } from '@mui/material';
import "./styles.css";

const goBack = () => {
    window.location = '/';
};

const PageNotFound = () => {
    return (
        <div className='pageNotFound'>
            <img src={errorPage} />
            <p style={{ marginTop: "50px" }}>The page you are looking for does not exist!</p>
            <Button variant="outlined" style={{
                color: "rgb(38, 38, 38)",
                border: "1px solid #ffcb00",
                background: "#ffcb00",
                margin: "50px"
            }} onClick={goBack}>Go back home</Button>
        </div>
    );
}

export default PageNotFound;