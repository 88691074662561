import logoLight from '../assets/logo_light.svg';
import { SEOLINKS } from "../constants";

const Footer = () => {
    return (
        <div className="footerContainer">
            <div className='footer'>

                <div className='footerLogo'>
                    <div><img className='logo' alt='Movesify Footer Logo' title='' src={logoLight} /></div>
                    <div className='socialLinks'>
                        <div><a href="https://facebook.com/movesify" target="_blank" aria-label="Movesify Facebook Page" className="fa-brands fa-facebook-f"></a></div>
                        <div><a href="https://www.instagram.com/movesify/" target="_blank" aria-label="Movesify Instagram Page" className="fa-brands fa-instagram"></a></div>
                        <div><a href="https://www.youtube.com/@movesify" target="_blank" aria-label="Movesify YouTube Page" className="fa-brands fa-youtube"></a></div>
                        <div><a href="https://www.linkedin.com/company/movesify/" target="_blank" aria-label="Movesify LinkedIn Page" className="fa-brands fa-linkedin"></a></div>
                        <div><a href="https://twitter.com/movesify" target="_blank" aria-label="Movesify Twitter Page" className="fa-brands fa-twitter"></a></div>
                    </div>
                </div>

                <div className='footerDivision'>
                    <div className='footerA'>
                        <div className='footerContent'>
                            <div className='linkSection'>
                                <ul>
                                    <li>Packers & Movers</li>
                                    <li><a href="/">Interstate Relocation</a></li>
                                    <li><a href="/">Intrastate Relocation</a></li>
                                    <li><a href="/">Business Partners</a></li>
                                </ul>
                            </div>

                            {/* <div className='linkSection'>
                                <ul>
                                    <li>End Mile Delivery</li>
                                    <li><a href="/">Package Deliver</a></li>
                                    <li><a href="/">Custom Branding</a></li>
                                    <li><a href="/">Business Partners</a></li>
                                    <li><a href="/">eCommerce</a></li>
                                </ul>
                            </div> */}

                            <div className='linkSection'>
                                <ul>
                                    <li>Truck Rentals</li>
                                    <li><a href="/">Witin City</a></li>
                                    <li><a href="/">End Mile Rentals</a></li>
                                    <li><a href="/">Interstate Rentals</a></li>
                                    <li><a href="/">Business Partners</a></li>
                                </ul>
                            </div>

                            <div className='linkSection'>
                                <ul>
                                    <li>Company</li>
                                    <li><a href="/">About Us</a></li>
                                    <li><a href="/">Careers</a></li>
                                    <li><a href="/blog/">Blog</a></li>
                                    <li><a href="/">Contact Us</a></li>
                                    <li><a href="/sitemap">Sitemap</a></li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className='footerB'>
                        <p>info@movesify.com</p>
                        <br />
                        <p>
                            30, 100 Feet Rd, Sidharata Colony<br /> Santhosapuram, Koramangala 2nd Block,<br /> Koramangala, Bengaluru - 560034<br /> Karnataka, India
                        </p>
                    </div>
                </div>
                <SEO />

                <div className='copySection'>
                    <div>Copyright {new Date().getFullYear()}. Movesify Technologies Pvt Ltd</div>
                    <div>
                        <ul>
                            <li>
                                <a href="/privacy">Privacy Policy</a>
                            </li>
                            <li>
                                <a href="/terms-and-conditions">Terms of Use</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}


const SEO = () => {
    return (
        <div className='seoLinks'>

            {SEOLINKS.map(data => {

                return (
                    <div className='seolinkSection' key={data.main}>
                        <h5><a href={"/packers-and-movers/" + data.main.toLowerCase().split(" ")[4]} >{data.main}</a></h5>
                        <div>
                            <ul>
                                {data.data.map(d => {
                                    return (<li key={d}><a href={process.env.REACT_APP_URL + "packers-and-movers/" + d.toLowerCase().split(" ").join("-")} key={d}>{d}</a></li>)
                                })}

                            </ul>
                        </div>
                    </div>

                )
            })}
        </div>
    );
}


export default Footer;