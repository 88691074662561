import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import * as DOMPurify from 'dompurify';

import "./blog.css";



const Blog = () => {

    let { id } = useParams();

    if (id) {
        return <Article />
    } else {
        return <BlogHome />
    }
}

const BlogHome = () => {
    const baseUrl = process.env.PUBLIC_URL;

    const [blogPosts, setBlogPosts] = useState([])
    useEffect(() => {
        fetch('https://api.movesify.com/blog/post/', {
            method: 'GET',
        }).then(response => response.json())
            .then(response => {
                console.log(response["response"])
                setBlogPosts(response["response"])
            });
    }, [])

    return (
        <div className='blogMainPage'>
            <div className='commonContainer'>
                <div className='blogHeadContainer'>
                    <span style={{
                        fontSize: '24px'
                    }}>The blog</span>
                    <h1 style={{
                        fontSize: '42px'
                    }}>Writings from our team</h1>
                    <p style={{
                        fontSize: '20px',
                        fontWeight: '100'
                    }}>The latest industry news, interviews, technologies, and resources.</p>

                    <hr className='blogHRlink' />
                </div>
            </div>

            <div className='commonContainer blogItems'>
                {blogPosts.map((data) => {
                    return (
                        <div className='item' key={data._id}>
                            <div className='image' style={{ backgroundImage: `url(${data.image})` }}></div>
                            <div className='articleSchedule'>
                                <div>{data.editorName}</div>
                                {/* <div className='articalDate'>13 Feb 2022</div> */}
                            </div>
                            <div className='contentWrapper'>
                                <h2><a className='articleLink' href={baseUrl + '/blog/' + data.seoUrl}>{data.title}</a></h2>
                                <p className='articleSummary'>
                                    {data.subTitle}
                                </p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div >);
}


const Article = () => {

    let { id } = useParams();
    const [article, setArticle] = useState([]);
    const [articleContent, setArticleContent] = useState("")
    useEffect(() => {
        fetch(`https://api.movesify.com/blog/post/${id}`, {
            method: 'GET',
        }).then(response => response.json())
            .then(response => {
                setArticle(response["response"][0]);
                setArticleContent(response["response"][0]["articleContent"]);
            });
    }, [])

    return (
        <div className='articlePage'>
            <div className='articleContainer'>
                <div className='articleHeader'>
                    <h1 className='articleHeading'>{article.title}</h1>
                    <p>{article.editorName}</p>
                </div>
                <img src={article.image} alt={article.title} width="100%" />
                <article>
                    {parse(articleContent)}
                </article>
            </div>
        </div>
    )
};

export default Blog;