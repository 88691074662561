import "./styles.css";
import "./styles/storage.scss";
import one from "../assets/warehouse.jpeg";
import findALocation from "../assets/find-a-location.svg";
import moveIn from "../assets/move-in.svg";
import reserveYourUnit from "../assets/reserve-your-unit.svg";
import {
  TextField,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { TbBugOff } from "react-icons/tb";
import { BiCctv } from "react-icons/bi";
import { RxMobile } from "react-icons/rx";
import { AiOutlineSafety } from "react-icons/ai";
import { useEffect, useState, useRef } from "react";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { CITIES } from "../constants";
import Autocomplete from "@mui/material/Autocomplete";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { FaBarcode } from "react-icons/fa";
import { GiCubes } from "react-icons/gi";
import { TbTruckDelivery } from "react-icons/tb";
import InputLabel from "@mui/material/InputLabel";

const Storage = () => {
  const [open, setOpen] = useState(false);
  const [storageType, setStorageType] = useState("personal");
  const [expectedMoveIn, setExpectedMoveIn] = useState("");
  const [storePlanning, setStorePlanning] = useState("");
  const [selectedStorageType, setSelectedStorageType] = useState(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const business = [
    {
      type: "Personal",
      list: [
        {
          id: 1,
          heading: "Small",
          subHeading: "2 bedrooms or less",
        },
        {
          id: 2,
          heading: "Medium",
          subHeading: "2-3 bedrooms",
        },
        {
          id: 3,
          heading: "Large",
          subHeading: "4+ bedrooms",
        },
      ],
    },
    {
      type: "Business",
      list: [
        {
          id: 21,
          heading: "Small",
          subHeading: "Ex. Restaurants",
        },
        {
          id: 22,
          heading: "Medium",
          subHeading: "Retail & Distribution",
        },
        {
          id: 23,
          heading: "Large",
          subHeading: "Corporate",
        },
      ],
    },
    {
      type: "Vehicle",
      list: [
        {
          id: 31,
          heading: "Two Wheeler",
          subHeading: "Bicycle, Motor Bike",
        },
        {
          id: 32,
          heading: "Four Wheelers",
          subHeading: "Car",
        },
      ],
    },
  ];

  function LeadModel() {
    return (
      <div>
        <Modal open={open} onClose={handleClose}>
          <div className="leadModelWrapper">
            <div className="leadModel">
              <div className="storagePricing" style={{ position: "relative" }}>
                <div className="closeButton">
                  <IconButton onClick={() => setOpen(false)}>
                    <CancelIcon size="large" />
                  </IconButton>
                </div>

                <div style={{ marginBottom: "10px" }}>
                  <FormControl>
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      style={{
                        fontWeight: "600",
                        color: "#000000",
                        fontSize: "24px",
                        marginBottom: "10px",
                        marginRight: "40px",
                      }}
                    >
                      What is your storage requirement?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={storageType}
                      onChange={(event) => setStorageType(event.target.value)}
                      className="nomsClass"
                    >
                      {business.map((data) => {
                        return (
                          <FormControlLabel
                            value={data.type}
                            key={data.type}
                            checked={
                              storageType.toLowerCase() ===
                              data.type.toLowerCase()
                            }
                            control={<Radio />}
                            label={data.type}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className="storageSelection">
                  {business.map((data) => {
                    if (
                      data.type.toLocaleLowerCase() ===
                      storageType.toLocaleLowerCase()
                    ) {
                      return data.list.map((subHead) => {
                        console.log(selectedStorageType);
                        return (
                          <div
                            className={`storageLableContentHolder ${
                              subHead.id === selectedStorageType ? "active" : ""
                            }`}
                            key={subHead.id}
                            onClick={() => setSelectedStorageType(subHead.id)}
                          >
                            <div className="storageIconsSelection">
                              <i className="fa-solid fa-warehouse"></i>
                            </div>
                            <div className="storageLabelsforSelection">
                              <p>{subHead.heading}</p>
                              <span>{subHead.subHeading}</span>
                            </div>
                          </div>
                        );
                      });
                    }
                  })}

                  {/* <div className="storageLableContentHolder">
                                        <div className="storageIconsSelection">
                                            <i className="fa-solid fa-warehouse"></i>
                                        </div>
                                        <div className="storageLabelsforSelection">
                                            <p>Document Storage</p>
                                            <span>Automobile Storage</span>
                                        </div>
                                    </div> */}
                </div>
              </div>

              <div className="storageFormData">
                <div className="textFielddiv">
                  <TextField fullWidth label="First Name" />
                </div>

                <div className="textFielddiv">
                  <TextField fullWidth label="Last Name" />
                </div>

                <div className="textFielddiv">
                  <TextField fullWidth label="Email" />
                </div>

                <div className="textFielddiv">
                  <TextField fullWidth label="Mobile Phone" />
                </div>

                <div className="textFielddiv">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      When do you need storage?
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      value={expectedMoveIn}
                      label="When do you need storage ?"
                      fullWidth
                      onChange={(event) => {
                        setExpectedMoveIn(event.target.value);
                      }}
                    >
                      {[
                        "Immediately",
                        "Next Month",
                        "In 2 Months",
                        "Planning",
                      ].map((data) => {
                        return <MenuItem value={data}>{data}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </div>

                <div className="textFielddiv">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label2">
                      How long for storage?
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-label2"
                      value={storePlanning}
                      label="How long for storage?"
                      fullWidth
                      onChange={(event) => {
                        setStorePlanning(event.target.value);
                      }}
                    >
                      {[
                        "1 Month or Less",
                        "2 Months",
                        "3 Months",
                        "4-6 Months",
                        "7-11 Months",
                        "12+ Months",
                        "Not Sure",
                      ].map((data) => {
                        return <MenuItem value={data}>{data}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <Button
                variant="contained"
                style={{
                  marginTop: 10,
                  color: "#262626",
                  backgroundColor: "#ffcb00",
                  textTransform: "none",
                  padding: "12px 30px",
                  fontSize: "18px",
                }}
                disableElevation
                onClick={() => {}}
                size="large"
                fullWidth
                endIcon={<ArrowForwardIcon />}
              >
                Book Storage Space
              </Button>

              <p className="packersTitle">
                By reserving online, you agree to our terms & conditions.
              </p>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  const handleClick = (heading, subHeading) => {
    handleOpen();
  };

  const mapaa = [
    {
      icon: "bi-house",
      lable: "Home Storage",
      description:
        "Store easlily all your home needs products and small home appliances.",
    },
    {
      icon: "bi-clock",
      lable: "24/7 Units Available",
      description:
        "We are available round the clock for you anytime and will service better.",
    },
    {
      icon: "bi-box-seam",
      lable: "Personal Storage",
      description:
        "Store all your small and big carton boxes with free from termite control.",
    },
    {
      icon: "bi-shield-lock",
      lable: "Safe Lockers",
      description:
        "Keep your important documents in a safe locker with a powerful volt system.",
    },
    {
      icon: "bi-file-earmark-richtext",
      lable: "Document Storage",
      description:
        "Store your documents for years and have a good after years.",
    },
    {
      icon: "bi-usb-drive",
      lable: "Digital Goods",
      description:
        "Store your digital goods like tv, cabinets, monitors, printers etc.",
    },
    {
      icon: "bi-ev-front",
      lable: "Vehicle Parkings",
      description:
        "We have car parking with safety measures for all the seasons.",
    },
    {
      icon: "bi-currency-rupee",
      lable: "Lowest Prices",
      description: "Affordable Prices ranges according to your requirements.",
    },
  ];
  return (
    <>
      <LeadModel />
      <div className="storageWrapper">
        <div
          style={{
            background: `url(${one})`,
            height: "700px",
            backgroundSize: "cover",
            backgroundPosition: "bottom",
          }}
        >
          <div className="storageMain">
            <div>
              <h1>
                Safe and Secure Storage for <br />
                Household & Business Needs
              </h1>
              <p>Reserve for free. Move in, contract-free!</p>

              <div className="storageForm">
                <div>
                  <TextField
                    helperText={false ? "Please enter your name" : ""}
                    //error={"customerNameError"}
                    value={"Bangalore"}
                    autoFocus
                    fullWidth
                    className="selectYourStorageCity"
                    autoComplete="off"
                    label="Your City"
                    margin="normal"
                    onChange={(e) => {
                      // setCustomerName(e.target.value);
                    }}
                    variant="outlined"
                  />
                </div>

                <div>
                  <Button
                    variant="contained"
                    style={{
                      marginTop: 10,
                      color: "#262626",
                      backgroundColor: "#ffcb00",
                      textTransform: "none",
                      padding: "12px 30px",
                      fontSize: "18px",
                    }}
                    disableElevation
                    onClick={handleClick}
                    size="large"
                    fullWidth
                    endIcon={<ArrowForwardIcon />}
                  >
                    Find Storage
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="commonWrapper"></div>
      </div>

      <div className="whyUsStorageWrapper">
        <div className="commonWrapper whyUsStorage whyUsStorageIcons">
          <div>
            <TbBugOff />
            <p>
              Pest <br />
              Control
            </p>
          </div>

          <div>
            <BiCctv />
            <p>Enhanced Security</p>
          </div>

          <div>
            <AiOutlineSafety />
            <p>Insurance Covered</p>
          </div>

          <div>
            <FaBarcode />
            <p>Barcode Tracking</p>
          </div>

          <div>
            <GiCubes />
            <p>Partial Retrivals</p>
          </div>

          <div>
            <TbTruckDelivery />
            <p>
              Door Pick <br />
              and Drop
            </p>
          </div>

          <div>
            <RxMobile />
            <p>Contact Free Move In</p>
          </div>
        </div>
      </div>

      <HowStorageWorks />

      <div className="dividerText">
        <div className="commonWrapper">
          <h2>
            If You Need The Space,{" "}
            <span className="highlight">We've Got The Place!</span>
          </h2>
        </div>
      </div>

      <div className="storageCategoryWrapper">
        <p>VALUE, CONVENIENCE, SECURITY AND SERVICES</p>
        <h2
          style={{
            fontSize: "2rem",
            borderBottom: "1px solid #ececec",
            paddingBottom: "20px",
          }}
        >
          Types of self storage we offer
        </h2>

        <div className="storageCategory commonWrapper">
          {mapaa.map((data) => {
            return (
              <div className="storageCategoryItem">
                <div className="storageCategoryIcon">
                  <i className={`bi ${data.icon}`}></i>
                </div>
                <h4>{data.lable}</h4>
                <div>{data.description}</div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

function HowStorageWorks() {
  return (
    <>
      <div className="commonWrapper storageWorks">
        <h2>How the self storage process works</h2>

        <div className="storageWorksIllustration">
          <div>
            <img src={findALocation} />
            <h3>1. Find your location</h3>
            <p>
              Find the closest SecureSpace self storage facility near you today.
            </p>
          </div>

          <div>
            <img src={reserveYourUnit} />
            <h3>2. Reserve your unit</h3>
            <p>
              Reserve a unit on our website and optionally pay for and sign your
              lease completely online!
            </p>
          </div>

          <div>
            <img src={moveIn} />
            <h3>3. Move in!</h3>
            <p>
              Arrive at the facility on your move in date, show a valid form of
              identification, and we'll get you moved in!
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Storage;
