import { atom } from "recoil";


export const leadDetails = atom({
    key: 'leadDetails', // unique ID (with respect to other atoms/selectors)
    default: {
        leadSource: "desktopWeb",
        customerDetails: {
            customerName: "",
            customerMobileNumber: "",
            customerEmailId: "",
            movingCity: "",
            distance: ""
        },
        movingFrom: {
            address: "",
            floor: "",
            liftAvailable: false
        },
        movingTo: {
            address: "",
            floor: "",
            liftAvailable: false
        },
        houseDetails: {
            size: "",
            abbreviation: "",
            priceWeekDay: "",
            priceWeekEnd: "",
            packingCharges: "",
            cartonbox: "",
            inventory: []
        },
        movementSlot: {
            lable: "",
            time: ""
        },
        leadStatus: "inital", // inital | partial | final | advance_paid" | "full_paid" | "payment_failed" | "completed" | "canceled"
        payments: {
            advance: {
                payment_id: "",
                order_id: "",
                signature: "",
                amount: "",
                payment_date: ""
            },
            balance: {
                payment_id: "",
                order_id: "",
                signature: "",
                amount: "",
                payment_date: ""
            },
            advance_payment_failed: {
                error: ""
            }

        },
        createdDate: new Date().getTime()
    }, // default value (aka initial value)
});