import RequirementModalPNM from '../components/RequirementModalPNM'
import TypeAnimation from 'react-type-animation';
import { useState } from 'react';
import playstore from '../assets/playstore.svg';
import appstore from '../assets/appstore.svg';
import { LABLES } from "../constants";
import { useRecoilState, useResetRecoilState } from "recoil";
import { leadDetails } from "../Atom";
import { initalLead } from "../components/leadFun";
import { TextField, Button, ToggleButtonGroup, ToggleButton } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const HomePage = () => {
    return (
        <div className='home'>
            <Hero />
            <HomeReviewSection />
            <How />
            <Why />
        </div >
    );
}


const Hero = () => {
    return (
        <div className='heroContainerBackground'>
            <div className='heroContainer'>
                <div className='heroSection'>
                    <div className='packersForm'>
                        <div style={{ width: "70%" }}>
                            <h1 className='typingTitle' style={{
                                fontSize: "3.5rem"
                            }}> We are {' '} <br />
                                <TypeAnimation
                                    cursor={false}
                                    repeat={Infinity}
                                    sequence={
                                        [
                                            'Delivery Company.', 2000,
                                            'Packers and Movers.', 2000,
                                            'End Mile Delivery.', 2000,
                                            'Truck Rental Company.', 2000,
                                            'Movesify.', 3000,]
                                    }
                                    wrapper="p"
                                />
                            </h1>
                            <div>
                                <h2 style={{ fontWeight: 400, color: '#fff', fontSize: '22px', marginTop: '40px' }}>
                                    Movesify is a logistics and delivery company that enables individuals and businesses to connect with delivery fleets through its mobile and web apps.
                                </h2>
                            </div>
                            <div>
                                <img className='applogo' src={appstore} />

                                <img className='applogo' src={playstore} />
                            </div>
                            <p style={{ color: "#ffcb00" }}> Keep watching this space, our app is coming soon. </p>
                        </div>
                    </div>
                    <div className='heroTitle'>
                        <HeroRequrementContainer />
                    </div>
                </div>
            </div >
        </div>
    );
};

const HeroRequrementContainer = () => {
    const [lead, setLead] = useRecoilState(leadDetails);
    const resetLeadDetails = useResetRecoilState(leadDetails);
    const [popup, setPopup] = useState(false);
    const [cityselection, setCityselection] = useState("within");
    const [customerName, setCustomerName] = useState("");
    const [customerMobileNumber, setCustomerMobileNumber] = useState("");
    const [customerEmailIdError, setCustomerEmailIdError] = useState(false);
    const [customerEmailId, setCustomerEmail] = useState("");
    const [customerNameError, setCustomerNameError] = useState(false);
    const [customerMobileNumberError, setCustomerMobileNumberError] = useState(false);

    function closePopUp() {
        sessionStorage.removeItem(LABLES.ORDER_ID);
        sessionStorage.removeItem(LABLES.LEAD_ID);
        setCustomerName("");
        setCustomerMobileNumber("");
        setCustomerEmail("");
        resetLeadDetails();
        setPopup(false);
    }

    function saveCustomerInitialData() {
        const regexExp = /^[6-9]\d{9}$/gi;

        const emailRegex = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

        if (customerName == "") {
            setCustomerNameError(true);
            return false;
        } else {
            setCustomerNameError(false);
        }

        if (!regexExp.test(customerMobileNumber)) {
            setCustomerMobileNumberError(true);
            return false;
        } else {
            setCustomerMobileNumberError(false);
        }

        if (!emailRegex.test(customerEmailId)) {
            setCustomerEmailIdError(true);
            return false;
        } else {
            setCustomerEmailIdError(false);
        }


        const leadData = {
            ...lead,
            customerDetails: {
                customerName: customerName,
                customerMobileNumber: customerMobileNumber,
                customerEmailId: customerEmailId,
                movingCity: cityselection
            }
        };
        setLead(leadData);

        setPopup(true);

        if (!sessionStorage.getItem(LABLES.LEAD_ID)) {
            initalLead(leadData);
        }
    }

    return (
        <div className="heroRequrementContainer">
            <h1>Looking to relocate ?</h1 >
            <h4 style={{ marginBottom: '20px', fontWeight: 400, color: '#6a6a6a' }}>Best Packers and Movers at Lowest Price.</h4>
            <div>
                <ToggleButtonGroup
                    color="primary"
                    fullWidth
                    value={cityselection}
                    exclusive
                    onChange={(event, value) => {
                        setCityselection(value);
                    }}
                >
                    <ToggleButton value="within">Within City</ToggleButton>
                    <ToggleButton value="between">Between Cities</ToggleButton>
                </ToggleButtonGroup>
                <TextField
                    helperText={customerNameError ? "Please enter your name" : ""}
                    error={customerNameError}
                    value={customerName}
                    id="filled-basic" fullWidth label="Your Name" margin="normal"
                    onChange={(e) => {
                        setCustomerName(e.target.value);
                    }}
                    variant="outlined" />
                <TextField
                    helperText={customerMobileNumberError ? "Please enter valid mobile number" : ""}
                    error={customerMobileNumberError}
                    value={customerMobileNumber}
                    id="filled-basic" fullWidth label="Your Mobile Number"
                    type='number'
                    inputProps={{
                        maxLength: 10
                    }}
                    onChange={(e) => {
                        setCustomerMobileNumber(e.target.value);
                    }} margin="dense" variant="outlined" />
                <TextField
                    value={customerEmailId}
                    helperText={customerEmailIdError ? "Please enter your email id" : ""}
                    error={customerEmailIdError}
                    id="filled-basic" fullWidth label="Your Email Id" margin="normal"
                    onChange={(e) => {
                        setCustomerEmail(e.target.value);
                    }}
                    variant="outlined" />
                <Button variant="contained" style={{
                    marginTop: 10,
                    color: "#262626",
                    backgroundColor: "#ffcb00",
                    padding: "12px 0px",
                    fontSize: "18px"
                }}
                    disableElevation
                    onClick={() => {

                        saveCustomerInitialData();

                    }}
                    size="large" fullWidth endIcon={<ArrowForwardIcon />}>Estimate Moving Cost</Button>

            </div>
            <RequirementModalPNM display={popup} closePopUp={closePopUp} />
        </div >
    );

}

export const HomeReviewSection = () => {
    return (
        <div className='whiteBackground HomeReviewSection'>
            <div className='how'>

                <h2>Why customers choose us</h2>

                <div className='howContainer'>
                    <div>
                        <h3>50,000+</h3> <p>Shipments handled</p>
                    </div>

                    <div>
                        <h3>3,000+</h3> <p>Offices & Service Centres</p>
                    </div>

                    <div>
                        <h3>52+</h3> <p>Airport Connections</p>
                    </div>

                    <div>
                        <h3>35,000+</h3> <p>Feet on Street</p>
                    </div>

                    <div>
                        <h3>2,500+</h3> <p>Network Cities</p>
                    </div>
                </div>
            </div>
        </div>
    );
}


const How = () => {
    return (
        <div className='whiteBackground'>
            <div className='how'>

                <h2>How Movesify packers and movers work?</h2>

                <div className='howContainer'>
                    <div>
                        <img src="./4905351.png" />
                        <h3>Submit your Movement details</h3> <p>Share us when and where you want to move</p>
                    </div>

                    <div>
                        <img src='./3527511.png' />
                        <h3>Get free instant quote and book a slot</h3> <p>Get instant quote for your local and national movements</p>
                    </div>

                    <div>
                        <img src='./2928979.png' />
                        <h3>We get you moved!</h3> <p>Enjoy safe & hassle free movement</p>
                    </div>
                </div>
            </div>
        </div>
    );
};



const Why = () => {
    return (
        <div className='why'>
            <div className='whyImage'>

            </div>
            <div className='whyUsContent'>

                <div className="whyUsContentWraper">
                    <h2 className="whyChooseUstitle">Why Movesify Packers and Movers ?</h2>
                    <ul>
                        <li>Book earliest movement slots</li>
                        <li>Dedicated movement manager</li>
                        <li>Get a relocation expert closer to you</li>
                        <li>Book your movement with a few clicks</li>
                        <li>Telephone booking &amp; support</li>
                        <li>Pay using Credit/Debit Cards</li>
                        <li>Reschedule your movement with no extra cost</li>
                        <li>Cancel your movement with full refund (if paid in advance)</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};


export default HomePage;