import "./styles/editor.css";

const Editor = () => {



    return (
        <>
            <div className="editorContainer">
                <div className="editor">
                    <div>
                        <h3>editable contents</h3>

                    </div>

                </div>
            </div>
        </>
    )
}


export default Editor;