import "./styles/sitemap.css";
import { CITIES } from "../constants.js";
import { useParams } from 'react-router-dom';

const goBack = () => {
    window.location = '/';
};

const Sitemap = () => {

    let { city } = useParams();

    if (!city) {
        return (
            <ShowCities />
        );
    } else {
        return (
            <ShowAreas />
        );
    }

}

const ShowCities = () => {
    return (
        <div className='siteMapWrapper'>
            <div className='sitemapContainer'>

                {CITIES.map((data, index) => {
                    return (
                        <div className='sitemapHolder' key={index}>
                            <h1><a href={"https://movesify.com/sitemap/" + data.label.toLocaleLowerCase()} target="_blank">{data.label}</a> </h1>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

const ShowAreas = () => {
    let { city } = useParams();
    return (
        <div className='siteMapWrapper'>
            <div className='sitemapContainer'>

                {CITIES.map((data, indexa) => {
                    if (data.label.toLocaleLowerCase() === city) {
                        return (
                            <div className='sitemapHolder' key={indexa}>
                                <h1><a href={"https://movesify.com/packers-and-movers/" + data.label.toLocaleLowerCase()} target="_blank">Packers and Movers in {data.label}</a> </h1>
                                <div className='sitemap'>
                                    {data.cities.map((area, indexb) => {
                                        const url = `https://movesify.com/packers-and-movers/${data.label.toLocaleLowerCase()}/${area.toLowerCase().split(" ").join("-")}`;
                                        return (
                                            <div key={indexb}>
                                                <a href={url} target="_blank">{area}</a>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    }
                })}
            </div>
        </div>
    );
}

export default Sitemap;


