import { lazy } from 'react';

import loadable from '@loadable/component'

import { useEffect, useState, useRef } from 'react';
import { LABLES, SEOLINKS, CITIES, META_DESCRIPTION } from "../constants";
import { useRecoilState, useResetRecoilState } from "recoil";
import { useParams } from 'react-router-dom';
import { leadDetails } from "../Atom";
import { initalLead } from "../components/leadFun";
import { TextField, Button, ToggleButtonGroup, ToggleButton } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import InputAdornment from '@mui/material/InputAdornment';
import Rating from '@mui/material/Rating';

import Carousel from 'react-material-ui-carousel'



import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import one from '../assets/1.gif';
import two from '../assets/2.gif';
import three from '../assets/3.gif';
import four from '../assets/4.gif';
import aboutPackers from '../assets/about-packers.jpg';
import google from '../assets/google.svg';
import customerSays from '../assets/customers-says.jpg';
import Frame1 from '../assets/Frame1.webp';
import Frame2 from '../assets/Frame2.webp';
import Frame3 from '../assets/Frame3.webp';
import Frame4 from '../assets/Frame4.webp';
import Frame2a from '../assets/Frame2a.webp';

import gallary1 from '../assets/gallery-1.webp';
import gallary2 from '../assets/gallery-2.webp';
import gallary3 from '../assets/gallery-3.webp';
import gallary4 from '../assets/gallery-4.webp';

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import Typography from '@mui/material/Typography';
import "./styles/packers.css";

const RequirementModalPNM = loadable(() => import('../components/RequirementModalPNM'))


const CookiePolicy = () => {
    return (
        <div className="cookiePolicyWrapper">
            <div className="cookiePolicy">
                <div>
                    <span className="cookiepolicyheading">Cookie Policy</span>
                    <p>Movesify uses cookies to deliver and enhance the quality of its services and to analyze traffic. If you agree, cookies are also used to serve advertising and to personalize the content and advertisements that you see. Learn more.</p>
                </div>

                <div className="cookieButtons">
                    <button className="accept">Accept</button>
                    <button className="reject">Reject</button>
                </div>
            </div>
        </div>

    );
}

const PackersAndMovers = () => {
    let headingTitle = "Premium Packers and Movers Service at an Affordable Price.";
    let headingDescriptionDefault = "Guaranteed On-time and Safe Delivery";
    const [heading, setHeading] = useState(headingTitle);
    const [headingDescription, setHeadingDescription] = useState(headingDescriptionDefault);
    const [requirementLables, setRequirementLables] = useState({
        heading: "Looking to relocate ?",
        subHeading: "Premium Packers and Movers Service at an Affordable Price."
    });
    const [areaData, setAreaData] = useState(null);

    const [areaIndex, setAreaIndex] = useState(0);

    let { city, area } = useParams();


    const setPageTitle = (data) => {
        document.title = `${data}`;
        setHeading(data);
    }

    useEffect(() => {
        if (city && area) {

            CITIES.map((data) => {
                if (data.label.toLocaleLowerCase() === city.toLocaleLowerCase()) {
                    if (data.cities.length > 0) {
                        data.cities.map((ci, index) => {
                            if (ci.split(" ").join("-").toLocaleLowerCase() === area.split(" ").join("-").toLocaleLowerCase()) {
                                const result = `Packers and Movers in ${ci}, ${data.label}`;
                                setAreaIndex(index);
                                setPageTitle(result);
                                setAreaData(ci);


                                let description = META_DESCRIPTION[index % META_DESCRIPTION.length];
                                description = description.replace("{city}", result);
                                setHeadingDescription(description);
                            }
                        });
                    } else {
                        const result = `Packers and Movers in ${data.label}`;
                        setPageTitle(result);
                    }
                }
            });

        } else if (city) {
            SEOLINKS.map((data) => data.data.map(d => {
                if (data.main && data.main.toLocaleLowerCase().split(" ").join("-") === city) {
                    const result = `${data.main}`;
                    setPageTitle(result);
                } else if (data.main && data.main.toLocaleLowerCase().split(" ")[4] === city) {
                    const result = `${data.main}`;
                    setPageTitle(result);
                }

                if (d.toLocaleLowerCase().split(" ").join("-") === city) {
                    const result = `Packers and Movers from ${d}`;
                    setPageTitle(result);
                } else if (d.toLocaleLowerCase().split(" ")[4] === city) {
                    const result = `Packers and Movers from ${d}`;
                    setPageTitle(result);
                }
            }));
        }
    });

    return (
        <div className='packers-and-movers-container'>
            <HelmetProvider>
                <Helmet>
                    <meta name="description" content={headingDescription} />
                </Helmet>
            </HelmetProvider>
            <Hero data={heading} area={areaData} requirementLables={requirementLables} headingDescription={headingDescription} handler={setRequirementLables} />
            <Services area={areaData} setRequirementLables={setRequirementLables} />
            <Pricing area={areaData} />
            <HowItWorks />
            <About area={areaData} />
            <Gallery />
            <FAQ data={heading} />
            <CookiePolicy />
        </div>
    );
}

const Gallery = (props) => {
    return (
        <div className='galleryWrapper'>
            <div className='galleryDiv'>
                <div className='galleryImage'><img src={gallary1} /></div>
            </div>
            <div className='galleryDiv'>
                <div className='galleryImage'><img src={gallary2} /></div>
            </div>
            <div className='galleryDiv'>
                <div className='galleryImage'><img src={gallary3} /></div>
            </div>
            <div className='galleryDiv'>
                <div className='galleryImage'><img src={gallary4} /></div>
            </div>

        </div>
    );
}

const Pricing = (props) => {

    const buttonStyles = {
        color: "#ffffff",
        backgroundColor: "#262626",
        fontSize: "16px",
        textTransform: 'none',
        padding: '4px 14px',
        marginTop: "20px",
        marginBottom: "10px",
        maxWidth: "200px"
    };

    const handleClick = (heading, subHeading) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }


    return (
        <div className='pricingWrapper'>
            <h2>Our Pricing</h2>
            <p className='howitWorksContent'>We offer the most competitive pricing for our top-quality moving services, <br />ensuring your relocation is affordable and hassle-free.</p>
            <div className='pricingcontainer'>

                <div className='pricingitem'>
                    <h2>1,999</h2>
                    <h3>1 BHK Starting from {props.area ? `in ${props.area}` : ''}</h3>
                    <Button variant="contained" style={buttonStyles}
                        disableElevation
                        onClick={handleClick}
                        size="large" fullWidth endIcon={<ArrowForwardIcon />}>Get started </Button>
                    <div>
                        <p>Includes 2 Labour</p>
                        <p>Insurance</p>
                        <p>Packing / Unpacking</p>
                        <p>Transport upto 10kms*</p>
                        <p>Inventory + 5 Carton box</p>
                    </div>
                </div>
                <div className='pricingitem'>
                    <h2>4,999</h2>
                    <h3>2 BHK Starting from {props.area ? `in ${props.area}` : ''}</h3>
                    <Button variant="contained" style={buttonStyles}
                        disableElevation
                        onClick={handleClick}
                        size="large" fullWidth endIcon={<ArrowForwardIcon />}>Get started </Button>
                    <div>
                        <p>Includes 3 Labour</p>
                        <p>Insurance</p>
                        <p>Packing / Unpacking</p>
                        <p>Transport upto 10kms*</p>
                        <p>Inventory + 10 Carton box</p>
                    </div>
                </div>
                <div className='pricingitem' >
                    <h2>8,999</h2>
                    <h3>3 BHK Starting from {props.area ? `in ${props.area}` : ''}</h3>
                    <Button variant="contained" style={buttonStyles}
                        disableElevation
                        onClick={handleClick}
                        size="large" fullWidth endIcon={<ArrowForwardIcon />}>Get started </Button>
                    <div>
                        <p>Includes 4 Labour</p>
                        <p>Insurance</p>
                        <p>Packing / Unpacking</p>
                        <p>Transport upto 10kms*</p>
                        <p>Inventory + 15 Carton box</p>
                    </div>
                </div>
                <div className='pricingitem'>
                    <h2>9,999</h2>
                    <h3>4BHK / Villa Starting from {props.area ? `in ${props.area}` : ''}</h3>
                    <Button variant="contained" style={buttonStyles}
                        disableElevation
                        onClick={handleClick}
                        size="large" fullWidth endIcon={<ArrowForwardIcon />}>Get started </Button>
                    <div>
                        <p>Includes 4 Labour</p>
                        <p>Insurance</p>
                        <p>Packing / Unpacking</p>
                        <p>Transport upto 10kms*</p>
                        <p>Inventory + 30 Carton box</p>
                    </div>
                </div>
                <div className='pricingitem'>
                    <h2>Let's talk</h2>
                    <h3>Corporate Relocation {props.area ? `in ${props.area}` : ''}</h3>
                    <Button variant="contained" style={buttonStyles}
                        disableElevation
                        onClick={handleClick}
                        size="large" fullWidth endIcon={<ArrowForwardIcon />}>Get started </Button>
                    <p>Our corporate relocation services are fully customizable to meet your unique business needs, ensuring a seamless transition for your employees and minimizing downtime.</p>
                </div>
            </div>
        </div>
    );
}

const HowItWorks = () => {
    return (
        <div className='howitworksWrapper'>
            <h2>How it works ?</h2>
            <p className='howitWorksContent'>We are dedicated to providing the best possible service to our customers, taking pride in our work and handling their belongings with the utmost care during every step of the moving process.</p>
            <div className='howitworkscontainer'>
                <div className='howitworksitem'>
                    <img src={Frame1} alt="Share your requirements" />
                    <h3>Share your requirements</h3>
                    <p>Just fill the above form</p>
                </div>
                <div className='howitworksitem'>
                    <img src={Frame2a} alt="Cost estimation" />
                    <h3>Cost estimation</h3>
                    <p>We estimate the cost of your move</p>
                </div>
                <div className='howitworksitem'>
                    <img src={Frame2} alt="Schedule and confirmation" />
                    <h3>Schedule and confirmation</h3>
                    <p>We shift your items with care and precausion</p>
                </div>
                <div className='howitworksitem' >
                    <img src={Frame3} alt="Packing and Unpacking" />
                    <h3>Packing and Unpacking</h3>
                    <p>We unpack and place your items as per your needs</p>
                </div>
                <div className='howitworksitem'>
                    <img src={Frame4} alt="Happy Relocation" />
                    <h3>Happy Relocation</h3>
                    <p>We complete our job with 100% customer satisfaction.</p>
                </div>
            </div>
        </div>
    );
}

const Services = (props) => {
    const handleClick = (heading, subHeading) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        props.setRequirementLables({
            heading,
            subHeading
        });
    }

    const buttonStyles = {
        color: "#262626",
        backgroundColor: "#ffcb00",
        fontSize: "16px",
        textTransform: 'none',
        padding: '4px 14px'
    };

    return (
        <div className='serviceWrapper'>
            <div className="ourServicesWrapper">
                <div className='services image1' onClick={() => handleClick("House Relocation ?", "Best Packers and Movers at Lowest Price.")}>
                    <div className='serviceHolder'>
                        <Button variant="contained" style={buttonStyles}
                            disableElevation
                            size="large" fullWidth endIcon={<ArrowForwardIcon />}>House Relocation {props.area ? `in ${props.area}` : ''}</Button>
                    </div>
                </div>

                <div className='services image2' onClick={() => handleClick("Office Relocation ?", "Premium Moving Service at affordable Price.")}>
                    <div className='serviceHolder'>
                        <Button variant="contained" style={buttonStyles}
                            disableElevation
                            size="large" fullWidth endIcon={<ArrowForwardIcon />}>Office Relocation {props.area ? `in ${props.area}` : ''}</Button>
                    </div>
                </div>

                <div className='services image3' onClick={() => handleClick("Vehicle Transport ?", "Best Car/Bike relocation ? Lets talk")}>
                    <div className='serviceHolder'>
                        <Button variant="contained" style={buttonStyles}
                            disableElevation
                            size="large" fullWidth endIcon={<ArrowForwardIcon />}>Vehicle Transport {props.area ? `in ${props.area}` : ''}</Button>
                    </div>
                </div>

                <div className='services image4' onClick={() => handleClick("Custom Transport ?", "We offer best rates. Lets talk")}>
                    <div className='serviceHolder'>
                        <Button variant="contained" style={buttonStyles}
                            disableElevation
                            size="large" fullWidth endIcon={<ArrowForwardIcon />}>Custom Transport {props.area ? `in ${props.area}` : ''}</Button>
                    </div>
                </div>


            </div>
        </div>
    );
};

const About = (props) => {
    return (
        <>
            <div className='aboutSectionWrapper'>
                <div className='aboutSectionImage'>
                    {/* <img src={aboutPackers} alt="Why customer choose us" height="100%" /> */}
                    <iframe width="100%" className="youtubeVideo" alt="Why customer choose us" title="Why customer choose us YouTube video" height="100%" src="https://www.youtube.com/embed/vDq8YWOHqN4"></iframe>
                </div>
                <div className='aboutSectionContent'>
                    <>
                        <div className='contentContainer'>
                            <div>
                                <h2>Why Choose us ?</h2>
                                <p>Movesify Packers and Movers bangalore is a professional, knowledgeable moving company that serves all your relocation needs. We bring in world class facilities that includes, packing and moving, loading/unloading, household shifting, office shifting, car transportation and corporate relocation services. Moreover, we are measured the only provider with the global reach and local capability to move anything anywhere at any time.</p>
                            </div>
                        </div>
                    </>
                </div>
            </div>

            <div className='whyCustomerChooseUsWrapper'>
                <div className='whyCustomerChooseUsContent'>
                    <div className='contentContainer'>
                        <div>
                            <h2>What Customer says </h2>
                            <CustomerReviews />
                        </div>
                    </div>
                </div>
                <div className='whyCustomerChooseUsImage'>
                    <img src={customerSays} alt="What Customers says about us" height="100%" />
                </div>
            </div>
        </>
    );
};


const CustomerReviews = () => {
    var items = [
        {
            name: "SHASHANK D K",
            designation: "Chartered Accountant",
            review: "Movesify Packers and Movers is the best moving company I have ever used. Their team was extremely polite, efficient, and went above and beyond to make my move stress-free. They carefully packed and loaded all my belongings, and everything arrived at my new home in perfect condition. The pricing was also very competitive and transparent, with no hidden fees or surprises. I would highly recommend this company to anyone looking for a reliable and trustworthy moving service.",
            reviewLink: "https://g.co/kgs/wtzEHi"
        },
        {
            name: "Dr. SURAJ SWARAJ",
            designation: "Dentist",
            review: "I recently used Movesify Packers and Movers for my office move, and the team was absolutely fantastic. They were extremely organized, efficient, and handled all of our office equipment and furniture with great care. They were also able to complete the move within the estimated time frame, which was a huge relief for us. The communication throughout the process was excellent, and I really appreciated the transparency and professionalism of the team. I would highly recommend Movesify Packers and Movers to anyone in need of a reliable and trustworthy moving service.",
            reviewLink: "https://g.co/kgs/gGiQti"
        },
        {
            name: "SUMAN BABU SAMPANGI",
            designation: "CEO & Founder - Aqwear",
            review: "I recently used Movesify Packers and Movers for my office move, and the team was absolutely fantastic. They were extremely organized, efficient, and handled all of our office equipment and furniture with great care. They were also able to complete the move within the estimated time frame, which was a huge relief for us. The communication throughout the process was excellent, and I really appreciated the transparency and professionalism of the team. I would highly recommend Movesify Packers and Movers to anyone in need of a reliable and trustworthy moving service.",
            reviewLink: "https://g.co/kgs/TxEjLs"
        }
    ];

    function Item(props) {
        return (
            <div className='reviewContent'>
                <p className='reviewQuote'>{props.item.review}</p>

                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <img src={google} style={{ width: "100px", margin: "0" }} />
                    <Rating name="read-only" value={5} readOnly />
                </div>
                <p className='reviewerName'><a href={props.item.reviewLink} target="_blank">{props.item.name}</a></p>
                <p>{props.item.designation}</p>
            </div>
        )
    }


    return (
        <div className='noms'>
            <Carousel indicators={false}>
                {
                    items.map((item, i) => <Item key={i} item={item} />)
                }
            </Carousel>
        </div>
    );
}


const Hero = (props) => {
    return (
        <>
            <div className='heroContainerBackground new'>
                <div className='heroContainer'>
                    <div className='heroSection new'>
                        <div className='packersForm new'>
                            <div className='packersmoverscontainer desktop'>
                                <h1>{props.data}</h1>
                                <p className='subTitle'>{props.headingDescription}</p>
                                <hr className='divider' />
                                <QuoteSection />
                            </div>
                        </div>
                        <div className='heroTitle new'>
                            <div className='packersmoverscontainer mobileHeading'>
                                <h1>{props.data}</h1>
                                <p className='subTitle'>{props.headingDescription}</p>
                                <hr className='divider' />
                            </div>
                            <HeroRequrementContainer requirementLables={props.requirementLables} />
                        </div>
                    </div>
                </div >

            </div>

            <div className='sectionWrapper'>
                <div className='commonContainer'>
                    <div className='contactSectionBlock'>
                        <div style={{ flex: 1 }}><h2>{props.area ? `Premium Moving Service in ${props.area} at Affordable Price` : 'Professional Moving Service at Affordable Price'}</h2></div>
                        <div className='contactContainer'>
                            <div className='contactBlock'>
                                <a href="tel:+918861776786" className='link'>
                                    <div><i className="fa fa-thin fa-phone-volume fa-shake"></i></div>
                                    <div>

                                        <div className='lable'>Give Us a Call</div>
                                        <div className='details'>+91-8861776786</div>
                                    </div>
                                </a>

                            </div>
                            <div className='contactBlock'>
                                <a href="mailto:info@movesify.com" className='link'>
                                    <div><i className="fa fa-light fa-envelope-open-text"></i></div>
                                    <div>
                                        <div className='lable'>Send Us a Email</div>
                                        <div className='details'>info@movesify.com</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
};

const FAQ = (propss) => {
    const Accordion = styled((props) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
            backgroundColor: "#ffffff"
        },
        '&:before': {
            display: 'none',
        },
    }));

    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', marginRight: "10px" }} />}
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor: '#fbfbfb',
        padding: "10px 20px",
        flexDirection: 'row',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    }));

    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(2),
        paddingLeft: "30px",
        borderTop: '1px solid rgba(0, 0, 0, .125)',
    }));

    const faq = [{
        question: "What services do you provide?",
        answer: "Movesify's packers and movers service provides a range of services, including packing, loading, transportation, unloading, and unpacking of household or commercial goods."
    },
    {
        question: "How do you charge for your services?",
        answer: "We charge based on the volume of goods, distance, and additional services required such as packing, unpacking, or storage."
    },
    {
        question: "How long does it take to complete a move?",
        answer: "The time taken to complete a move depends on the volume of goods, distance, and other factors. Our team will provide you with an estimated timeline based on your specific requirements."
    },
    {
        question: "Do you provide insurance for the goods being moved?",
        answer: "Yes, we provide insurance for goods being moved. Our team will provide you with details on the insurance options available."
    },
    {
        question: "How do you ensure the safety of my belongings during transit?",
        answer: "We take every precaution to ensure the safety of your belongings during transit. Our team uses high-quality packing materials, secure loading and unloading procedures, and proper transportation methods to minimize the risk of damage."
    },
    {
        question: "Do you provide storage facilities?",
        answer: "Yes, we provide storage facilities for short-term or long-term periods as required."
    },
    {
        question: "Can you move specialized items like pianos or artwork?",
        answer: "Yes, we have the expertise and experience to move specialized items like pianos or artwork. Our team will take extra care to ensure that these items are moved safely."
    },
    {
        question: "What if I need to cancel or reschedule my move?",
        answer: "We understand that plans can change. Please notify us as soon as possible if you need to cancel or reschedule your move. We will work with you to find a suitable alternative."
    },
    {
        question: "Can you provide references from previous customers?",
        answer: "Yes, we can provide references from previous customers upon request."
    },
    {
        question: "How do I book your services?",
        answer: "You can book our services by calling our customer service team or filling out an online form. Our team will work with you to understand your requirements and provide a quote for the services required."
    },
    {
        question: "What should I do if my belongings are damaged during the move?",
        answer: "If your belongings are damaged during the move, please contact us immediately. We will work with you to resolve the issue as quickly as possible."
    }]

    return (
        <div className='faqWrapper'>
            <div style={{ backgroundColor: "#ffffff", padding: "40px" }}>
                <div className='commonContainer'>
                    <div className='FAQlable'><h2>Frequently Asked Question (FAQ) about {propss.data}</h2></div>
                    <br />
                    <div className='accordionContainer'>

                        {faq.map((data, index) => {
                            return (
                                <Accordion key={index}>
                                    <AccordionSummary aria-controls={`panel2d-content-${index}`}>
                                        <Typography sx={{ fontSize: "1.2em" }}>{data.question}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            {data.answer}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}


const QuoteSection = () => {
    return (
        <div className='quotesBlock'>
            <div className='box'>
                <img src={four} alt="Guaranteed Lowest Prices" className="quoteIcon" />
                <h2>Guaranteed Lowest Prices</h2>
                <p>100% price match and quality service guarantee</p>
                <p className='description'>Moving at a price you can afford- we'll match any competitor's quote.
                    Get exact prices based on shifting details.
                    No surprises on your shifting day, and no hidden costs.
                    Save Money - Save Time - No Damages</p>
            </div>
            <div className='box'>
                <img src={one} alt="Free Cancellation & Reschedule" className="quoteIcon" />
                <h2>Free Cancellation & Reschedule</h2>
                <p>Book now, cancel or change anytime later</p>
                <p className='description'>Book your slot today and change the date later anytime
                    FREE cancelation and full refunds with no questions asked
                    Book slots in advance to avoid high demand and high prices
                </p>
            </div>
            <div className='box'>
                <img src={two} alt="High Quality Service" className="quoteIcon" />
                <h2>High Quality Service</h2>
                <p>Professional service to guarantee the safety of your valuable inventory</p>
                <p className='description'>Ensure safe movement of household goods by our top quality service experts.
                    No to local vendors, only verified and best-in-class packers and movers expert are chosen after stringent quality checks process.
                    No price changes on the day of your movement, professional behaviour and obligation to the quoted price.</p>
            </div>
            <div className='box'>
                <img src={three} alt="Dedicated Service Manager" className="quoteIcon" />
                <h2>Dedicated Service Manager</h2>
                <p>Be tension free and let the service manager take care</p>
                <p className='description'>Move manager to make your shifting hassle-free
                    Move manager makes sure no delays happen on the shifting day
                    Reminders about your movement, solving problems before they happen</p>
            </div>
        </div>
    );
}

const HeroRequrementContainer = (props) => {
    const [lead, setLead] = useRecoilState(leadDetails);
    const resetLeadDetails = useResetRecoilState(leadDetails);
    const [popup, setPopup] = useState(false);
    const [cityselection, setCityselection] = useState("within");
    const [customerName, setCustomerName] = useState("");
    const [customerMobileNumber, setCustomerMobileNumber] = useState("");
    const [customerEmailIdError, setCustomerEmailIdError] = useState(false);
    const [customerEmailId, setCustomerEmail] = useState("");
    const [customerNameError, setCustomerNameError] = useState(false);
    const [customerMobileNumberError, setCustomerMobileNumberError] = useState(false);
    const inputNameRef = useRef();


    useEffect(() => {
        setTimeout(() => inputNameRef.current.focus(), 400);
    }, [props]);

    function closePopUp() {
        sessionStorage.removeItem(LABLES.ORDER_ID);
        sessionStorage.removeItem(LABLES.LEAD_ID);
        setCustomerName("");
        setCustomerMobileNumber("");
        setCustomerEmail("");
        resetLeadDetails();
        setPopup(false);
    }

    function saveCustomerInitialData() {
        const regexExp = /^[6-9]\d{9}$/gi;

        const emailRegex = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

        if (customerName == "") {
            setCustomerNameError(true);
            return false;
        } else {
            setCustomerNameError(false);
        }

        if (!regexExp.test(customerMobileNumber)) {
            setCustomerMobileNumberError(true);
            return false;
        } else {
            setCustomerMobileNumberError(false);
        }

        // if (!emailRegex.test(customerEmailId)) {
        //     setCustomerEmailIdError(true);
        //     return false;
        // } else {
        //     setCustomerEmailIdError(false);
        // }


        const leadData = {
            ...lead,
            customerDetails: {
                customerName: customerName,
                customerMobileNumber: customerMobileNumber,
                customerEmailId: customerEmailId,
                movingCity: cityselection
            }
        };
        setLead(leadData);

        setPopup(true);

        if (!sessionStorage.getItem(LABLES.LEAD_ID)) {
            initalLead(leadData);
        }
    }

    return (
        <>
            <div className='heroRequrementContainer'>
                <h2 className='requirementHeading'>{props.requirementLables.heading}</h2>
                <p style={{ color: "#6a6a6a" }}>{props.requirementLables.subHeading}</p>
                <div>

                    <TextField
                        helperText={customerNameError ? "Please enter your name" : ""}
                        error={customerNameError}
                        value={customerName}
                        autoFocus
                        fullWidth
                        inputRef={inputNameRef}
                        autoComplete='off'
                        id="yourName" label="Your Name" margin="normal"
                        onChange={(e) => {
                            setCustomerName(e.target.value);
                        }}
                        variant="outlined" />

                    <TextField
                        helperText={customerMobileNumberError ? "Please enter valid mobile number" : ""}
                        error={customerMobileNumberError}
                        value={customerMobileNumber}
                        autoComplete='off'
                        id="yourMobileNumber" fullWidth label="Your Mobile Number"
                        type='number'
                        InputProps={{
                            maxLength: 10,
                            startAdornment: <InputAdornment position="start">+91 - </InputAdornment>,
                        }}
                        style={{ marginBottom: '16px' }}
                        onChange={(e) => {
                            setCustomerMobileNumber(e.target.value);
                        }} margin="dense" variant="outlined" />

                    <Autocomplete
                        disablePortal
                        id="movingFromComboBox"
                        options={CITIES}
                        fullWidth
                        style={{ marginBottom: '16px' }}
                        renderInput={(params) => <TextField {...params} label="Moving From" />}
                    />

                    <Autocomplete
                        disablePortal
                        id="movingToComboBox"
                        options={CITIES}
                        fullWidth
                        renderInput={(params) => <TextField {...params} label="Moving to" />}
                    />





                    <Button variant="contained" style={{
                        marginTop: 10,
                        color: "#262626",
                        backgroundColor: "#ffcb00",
                        padding: "12px 0px",
                        fontSize: "18px"
                    }}
                        disableElevation
                        onClick={() => {

                            saveCustomerInitialData();

                        }}
                        size="large" fullWidth endIcon={<ArrowForwardIcon />}>Estimate Moving Cost</Button>

                </div>
                <RequirementModalPNM display={popup} closePopUp={closePopUp} />
            </div>

            <div className='trustBadge'><i className="fa-solid fa-shield-heart"></i>Trusted by 50k+ happy customers.</div>
        </>
    );

}

export default PackersAndMovers;