import PUNE_CITIES from "./constants/pune.json";
import BANGALORE_CITIES from "./constants/bangalore.json";
import DELHI_CITIES from "./constants/delhi.json";
import MUMBAI_CITIES from "./constants/mumbai.json";
import CHENNAI_CITIES from "./constants/chennai.json";
import HYDRABAD_CITIES from "./constants/hydrabad.json";
import AHMEDABAD_CITIES from "./constants/ahmedabad.json";
import GOA_CITIES from "./constants/goa.json";
import KERALA_CITIES from "./constants/kerala.json";
import SEOLINKS_FILE from "./constants/seoLinks.json";



export const LABLES = {
    HELLO: "hello",
    MOVING_FROM: "Moving From",
    MOVING_TO: "Moving To",
    LEAD_ID: "leadId",
    ORDER_ID: "orderId"
};

export const SEOLINKS = SEOLINKS_FILE;






export const CITIES = [
    { label: "Bangalore", cities: BANGALORE_CITIES },
    { label: "Delhi", cities: DELHI_CITIES },
    { label: "Mumbai", cities: MUMBAI_CITIES },
    { label: "Hyderabad", cities: HYDRABAD_CITIES },
    { label: "Chennai", cities: CHENNAI_CITIES },
    { label: "Chandigarh", cities: [] },
    { label: "Pune", cities: PUNE_CITIES },
    { label: "Kolkata", cities: [] },
    { label: "Jaipur", cities: [] },
    { label: "Ahmedabad", cities: AHMEDABAD_CITIES },
    { label: "Lucknow", cities: [] },
    { label: "Patna", cities: [] },
    { label: "Goa", cities: GOA_CITIES },
    { label: "Kerala", cities: KERALA_CITIES },
];

export const META_DESCRIPTION = ["Looking for reliable and affordable Packers and Movers for house shifting services in {city}? Look no further than our top-rated services!",
    "Get the best rates for house shifting services in {city} with our affordable Packers and Movers. Contact us today to schedule your move!",
    "Our affordable and reliable Packers and Movers provide the best house shifting services in {city}. Contact us for a stress-free move!",
    "For the best relocation and house shifting services in {city}, choose our top-rated Packers and Movers at unbeatable rates.",
    "Trust our professional Packers and Movers for the most affordable and efficient house shifting services in {city}. Contact us today!",
    "Moving can be stressful, but with our reliable Packers and Movers, you can rest assured your house shifting services in {city} will be stress-free.",
    "Looking for the best rates for house shifting services in {city}? Our experienced Packers and Movers provide the most affordable solutions.",
    "Our team of expert Packers and Movers are dedicated to providing the best house shifting services in {city} at the most affordable rates.",
    "Choose our experienced Packers and Movers for the most reliable and affordable house shifting services in {city}. Contact us now!",
    "When it comes to house shifting services in {city}, our professional Packers and Movers offer the best rates and top-quality service.",
    "Get a stress-free move with our experienced Packers and Movers providing the best house shifting services in {city} at unbeatable prices.",
    "Our affordable Packers and Movers provide the best rates for house shifting services in {city} while ensuring your belongings are safe and secure.",
    "For the best relocation and house shifting services in {city} at affordable prices, look no further than our trusted Packers and Movers.",
    "Trust our reliable Packers and Movers for affordable and efficient house shifting services in {city}. Contact us today to get started.",
    "Our experienced Packers and Movers provide top-quality house shifting services in {city} at unbeatable prices. Contact us now for a quote!",
    "Choose our trusted Packers and Movers for affordable and efficient house shifting services in {city}. Contact us today for a stress-free move!",
    "With our reliable Packers and Movers, you can expect the best rates and top-quality house shifting services in {city}. Contact us now to learn more!",
    "Our professional Packers and Movers provide the best relocation and house shifting services in {city} at affordable prices. Contact us today!",
    "Choose our experienced Packers and Movers for the most efficient and affordable house shifting services in {city}. Contact us today to get started.",
    "Looking for affordable and reliable Packers and Movers for house shifting services in {city}? Our team is dedicated to your stress-free move.",
    "Our experienced Packers and Movers provide the best rates for house shifting services in {city} while ensuring your belongings arrive safely and on time.",
    "When it comes to house shifting services in {city}, our professional Packers and Movers provide unbeatable rates and top-quality service.",
    "Trust our reliable Packers and Movers for efficient and affordable house shifting services in {city}. Contact us now to schedule your move!",
    "For affordable and efficient house shifting services in {city}, choose our experienced Packers and Movers. Contact us today to get started!",
    "Trustworthy packers and movers in {city} for your house relocation needs. Best rates guaranteed.",
    "Looking for affordable house shifting services in {city}? Contact us for unbeatable prices!",
    "Get top-notch home shifting services in {city} at the best price. Contact us for a quote today!",
    "We offer reliable and affordable packers and movers services for your house relocation needs in {city}.",
    "Stress-free house shifting services in {city} at affordable rates. Contact us for a hassle-free move!",
    "Let us handle your house relocation needs in {city} with our best-in-class packers and movers services.",
    "Get the best rates for your house shifting needs in {city} with our affordable packers and movers services.",
    "Looking for professional packers and movers services for your house relocation needs in {city}? Look no further!",
    "House shifting services in {city} made easy with our trusted and affordable packers and movers services.",
    "Experience hassle-free home shifting services in {city} with our reliable and affordable solutions.",
    "Get the best price for your house relocation needs in {city} with our trusted packers and movers services.",
    "Looking for reliable and affordable house shifting services in {city}? Contact us for a quote now!",
    "Enjoy a smooth house shifting experience in {city} with our affordable and professional packers and movers services.",
    "Need help with your home shifting needs in {city}? Contact us for top-notch packers and movers services at the best price.",
    "Trust us for your house relocation needs in {city} and enjoy our affordable and reliable packers and movers services.",
    "Get the best price for your house shifting needs in {city} with our trusted and affordable packers and movers services.",
    "Our packers and movers services in {city} ensure a hassle-free and cost-effective house relocation experience.",
    "Make your house shifting experience in {city} stress-free with our professional and affordable packers and movers services.",
    "Need help with your home relocation needs in {city}? Contact us for top-quality packers and movers services at the best rates.",
    "Let us take care of your house shifting needs in {city} with our reliable and affordable packers and movers services.",
    "Get a hassle-free house relocation experience in {city} with our top-rated packers and movers services.",
    "Choose us for your house shifting needs in {city} and enjoy our affordable and professional packers and movers services.",
    "Experience the best house shifting services in {city} with our trusted and affordable packers and movers solutions.",
    "Contact us for reliable and affordable home shifting services in {city}. We guarantee the best rates!",
    "Need reliable packers and movers services for your house relocation needs in {city}? Contact us for a hassle-free move.",
    "Trust us for your house shifting needs in {city} and enjoy our affordable and reliable packers and movers services."];


// SEOLINKS.map((city)=>{
    // const url = `https://movesify.com/packers-and-movers/${city.label.toLowerCase().split(" ").join("-")}`;
    // console.log(url);
//     city.data.map((area)=>{
//         const url = `https://movesify.com/packers-and-movers/${area.toLowerCase().split(" ").join("-")}`;
//         console.log(url);
//     })
// });

// BANGALORE_CITIES.map((area)=>{
//     const url = `https://movesify.com/packers-and-movers/bangalore/${area.toLowerCase().split(" ").join("-")}`;
//     console.log(url);
// });

