import { LABLES } from "../constants";
export const updateLead = async (lead) => {

    if (sessionStorage.getItem(LABLES.LEAD_ID)) {

        const rawResponse = await fetch('https://api.movesify.com/packers/updateLead', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'leadId': sessionStorage.getItem(LABLES.LEAD_ID)
            },
            body: JSON.stringify(lead)
        })
        const content = await rawResponse.json();


    } else {
        initalLead();
    }

};


export const generateOrder = async (amount) => {

    if (sessionStorage.getItem(LABLES.LEAD_ID)) {

        const rawResponse = await fetch('https://api.movesify.com/packers/generateOrder', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'leadId': sessionStorage.getItem(LABLES.LEAD_ID),
                'amount': amount
            }
        })
        const content = await rawResponse.json();
        sessionStorage.setItem(LABLES.ORDER_ID, content.response.id);
        return content.response.id;

    }

};

export const initalLead = async (lead) => {
    const rawResponse = await fetch('https://api.movesify.com/packers/lead', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(lead)
    });
    const content = await rawResponse.json();

    sessionStorage.setItem(LABLES.LEAD_ID, content.response.insertedId);
}