import { useState, useMemo, useEffect } from 'react';
import {
    TextField, Button, MenuItem, Select, InputLabel, ToggleButtonGroup, ToggleButton, IconButton, FormControlLabel, Checkbox, Divider, Card, CardContent,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Chip,
} from '@mui/material';
import moment from 'moment'
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import Box from '@mui/material/Box';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './calender.css';

import { LABLES } from "../constants";


import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { useRecoilState } from "recoil";
import { updateLead, generateOrder } from './leadFun';

import { leadDetails } from "../Atom";
let currency = Intl.NumberFormat('en-IN', {
    style: "currency",
    currency: "INR",
});




const homeSizeData = [
    {
        "tabLable": "1 RK",
        "type": "1rk",

        "subtype": [
            {
                size: "1rk",
                abbreviation: "1 Room Kitchen",

                priceWeekDay: "3420",
                priceWeekEnd: "3990",
                packingCharges: "5100",
                cartonbox: "10",
                inventory: [
                    { code: "", quantity: 1, label: "1 TV & TV Stand" },
                    { code: "", quantity: 1, label: "2 Chairs" },
                    { code: "", quantity: 1, label: "1 Washing Machine" },
                    { code: "", quantity: 1, label: "1 Fridge" },
                    { code: "", quantity: 1, label: "1 Single Cot" },
                ]
            }
        ],
    },
    {
        "tabLable": "1 BHK",
        "type": "1bhk",
        "subtype": [
            {
                size: "1bhklite",
                abbreviation: "1 Bed Room Hall Kitchen Lite",

                priceWeekDay: "5510",
                priceWeekEnd: "6175",
                packingCharges: "7523",
                cartonbox: "10",
                inventory: [
                    { code: "", quantity: 1, label: "1 TV & TV Stand" },
                    { code: "", quantity: 1, label: "2 Chairs" },
                    { code: "", quantity: 1, label: "1 Washing Machine" },
                    { code: "", quantity: 1, label: "1 Fridge" },
                    { code: "", quantity: 1, label: "1 Single Cot" },
                    { code: "", quantity: 1, label: "1 Almirah" }
                ]
            },
            {
                size: "1bhkheavy",
                abbreviation: "1 Bed Room Hall Kitchen Heavy",

                priceWeekDay: "7743",
                priceWeekEnd: "8693",
                packingCharges: "8968",
                cartonbox: "15",
                inventory: [
                    { code: "", quantity: 1, label: "1 TV & TV Stand" },
                    { code: "", quantity: 1, label: "4 Chairs" },
                    { code: "", quantity: 1, label: "1 Washing Machine" },
                    { code: "", quantity: 1, label: "1 Double Cot" },
                    { code: "", quantity: 1, label: "1 Single Cot" },
                    { code: "", quantity: 1, label: "2 Matress(Rolling)" },
                    { code: "", quantity: 1, label: "15 Carton Box" },
                    { code: "", quantity: 1, label: "1 Almirah" },
                    { code: "", quantity: 1, label: "1 Center Table" },
                    { code: "", quantity: 1, label: "1 Sofa 3" },
                    { code: "", quantity: 1, label: "1 Water Purifier" }
                ]
            }
        ],
    },
    {
        "tabLable": "2 BHK",
        "type": "2bhk",
        "subtype": [
            {
                size: "2bhklite",
                abbreviation: "2 Bed Room Hall Kitchen Lite",

                priceWeekDay: "8930",
                priceWeekEnd: "9880",
                packingCharges: "10158",
                cartonbox: "20",
                inventory: [
                    { code: "", quantity: 1, label: "1 Sofa(3+2)" },
                    { code: "", quantity: 1, label: "1 Center Table" },
                    { code: "", quantity: 1, label: "1 Iron Almirah / 2 door wardrobe" },
                    { code: "", quantity: 1, label: "1 TV" },
                    { code: "", quantity: 1, label: "6 Chairs" },
                    { code: "", quantity: 1, label: "1 Washing Machine" },
                    { code: "", quantity: 1, label: "1 Fridge" },
                    { code: "", quantity: 1, label: "2 Double Cot" },
                    { code: "", quantity: 1, label: "1 Dining Table/4 Chairs" },
                    { code: "", quantity: 1, label: "1 Shoe Rack" },
                    { code: "", quantity: 1, label: "3 Matress" },
                    { code: "", quantity: 1, label: "1 Water Purefire" },
                    { code: "", quantity: 1, label: "15-20 Carton Box" }
                ]
            },
            {
                size: "2bhkheavy",
                abbreviation: "2 Bed Room Hall Kitchen Heavy",

                priceWeekDay: "11970",
                priceWeekEnd: "12920",
                packingCharges: "12708",
                cartonbox: "30",
                inventory: [
                    { code: "", quantity: 1, label: "1 Sofa(3+2)" },
                    { code: "", quantity: 1, label: "1 Center Table" },
                    { code: "", quantity: 1, label: "1 2 Iron Almirah" },
                    { code: "", quantity: 1, label: "1 TV" },
                    { code: "", quantity: 1, label: "8 Chairs" },
                    { code: "", quantity: 1, label: "1 Washing Machine" },
                    { code: "", quantity: 1, label: "1 Fridge" },
                    { code: "", quantity: 1, label: "1 Diwan Cot" },
                    { code: "", quantity: 1, label: "2 Double Cot" },
                    { code: "", quantity: 1, label: "3 Double Matress" },
                    { code: "", quantity: 1, label: "1 Dinning Table with chairs" },
                    { code: "", quantity: 1, label: "1 Shoe Rack" },
                    { code: "", quantity: 1, label: "30 Carton box" },
                    { code: "", quantity: 1, label: "Study Table / Computer Table" },
                    { code: "", quantity: 1, label: "1 Water Purifier" }
                ]
            }
        ],
    },
    {
        "tabLable": "3 BHK",
        "type": "3bhk",
        "subtype": [
            {
                size: "3bhk",
                abbreviation: "3 Bed Room Hall Kitchen",
                priceWeekDay: "13823",
                priceWeekEnd: "14535",
                packingCharges: "14790",
                cartonbox: "35",
                inventory: [
                    { code: "", quantity: 1, label: "1 Sofa (3+2+1)" },
                    { code: "", quantity: 1, label: "2 Center Table" },
                    { code: "", quantity: 1, label: "3 Iron Almirah / 3 Door wardrob" },
                    { code: "", quantity: 1, label: "1 TV" },
                    { code: "", quantity: 1, label: "8 Charis" },
                    { code: "", quantity: 1, label: "10 Carton Box" },
                    { code: "", quantity: 1, label: "1 Washing Machine" },
                    { code: "", quantity: 1, label: "1 Fridge" },
                    { code: "", quantity: 1, label: "1 Single Cot" },
                    { code: "", quantity: 1, label: "2 Double Cot" },
                    { code: "", quantity: 1, label: "3 Double Matress" },
                    { code: "", quantity: 1, label: "1 Dining Table" },
                    { code: "", quantity: 1, label: "1 Shoe Rack" },
                    { code: "", quantity: 1, label: "35 Carton box" },
                    { code: "", quantity: 1, label: "Study Table" },
                    { code: "", quantity: 1, label: "Water Purifier" }
                ]
            }
        ],
    }
];

const timeSlot = [{
    time: "8am10am",
    lable: "8 AM to 10 AM"
},
{
    time: "10am12pm",
    lable: "10 AM to 12 PM"
},
{
    time: "12pm2pm",
    lable: "12 PM to 2 PM"
},
{
    time: "2pm4pm",
    lable: "2 PM to 4 PM"
},
{
    time: "4pm6pm",
    lable: "4 PM to 6 PM"
}];




const RequirementModalPNM = (props) => {
    const [lead, setLead] = useRecoilState(leadDetails);

    const { closePopUp } = props;


    const [city, setCity] = useState("bengaluru");


    const [movingFromAddress, setMovingFromAddress] = useState(null);
    const [movingFromFloor, setMovingFromFloor] = useState(0);
    const [movingFromFloorError, setMovingFromFloorError] = useState(false);
    const [movingFromLift, setMovingFromLift] = useState(false);
    const [movingFromAddressError, setMovingFromAddressError] = useState(false);


    const [movingToAddress, setMovingToAddress] = useState(null);
    const [movingToFloor, setMovingToFloor] = useState(0);
    const [movingToFloorError, setMovingToFloorError] = useState(false);
    const [movingToLift, setMovingToLift] = useState(false);
    const [movingToAddressError, setMovingToAddressError] = useState(false);

    const [step, setStep] = useState(0);

    const [tabType, setTabType] = useState(homeSizeData[0].type);
    const [selectedHouse, setSelectedHouse] = useState({ size: '1rk', abbreviation: '1 Room Kitchen' });


    async function getDistance(distanceType) { //
        if (distanceType === "between") {
            const matrix = new window.google.maps.DistanceMatrixService();
            await matrix.getDistanceMatrix({
                origins: [movingFromAddress?.description],
                destinations: [movingToAddress?.description],
                travelMode: window.google.maps.TravelMode.DRIVING,
            }, async function (response, status) {

                const distance = parseInt(response["rows"][0].elements[0].distance.value) / 1000;


                const leadData = {
                    ...lead,
                    customerDetails: {
                        ...lead.customerDetails,
                        distance: distance
                    }
                };
                setLead(leadData);
            });
        }
    }

    async function validateAddress(sc) {

        let isAddressValidationComplete = false;

        const mf = document.querySelector("#movingFrom").value;
        const mt = document.querySelector("#movingTo").value;
        let distance = 0;


        if (mf === '') {
            setMovingFromAddressError(true);
            isAddressValidationComplete = false;
            return;
        } else {
            setMovingFromAddressError(false);
            isAddressValidationComplete = true;
        }

        if (mt === '') {
            setMovingToAddressError(true);
            isAddressValidationComplete = false;
            return;
        } else {
            setMovingToAddressError(false);
            isAddressValidationComplete = true;
        }


        if (lead.customerDetails.movingCity === "within") {

            if (movingFromAddress.description.toLowerCase().indexOf(city) < 0) {
                setMovingFromAddressError(true);
                isAddressValidationComplete = false;
                return;
            } else {
                setMovingFromAddressError(false);
                isAddressValidationComplete = true;
            }

            if (movingToAddress.description.toLowerCase().indexOf(city) < 0) {
                setMovingToAddressError(true);
                isAddressValidationComplete = false;
                return;
            } else {
                setMovingToAddressError(false);
                isAddressValidationComplete = true;
            }
        }

        if (movingFromFloor.toString() === '' || parseInt(movingFromFloor) < 0) {
            setMovingFromFloorError(true);
            isAddressValidationComplete = false;
            return;
        } else {
            setMovingFromFloorError(false);
            isAddressValidationComplete = true;
        }

        if (movingToFloor.toString() === '' || parseInt(movingToFloor) < 0) {
            setMovingToFloorError(true);
            isAddressValidationComplete = false;
            return;
        } else {
            setMovingToFloorError(false);
            isAddressValidationComplete = true;
        }

        if (isAddressValidationComplete) {
            const leadData = {
                ...lead,
                customerDetails: {
                    ...lead.customerDetails,
                    movingCity: sc,
                    distance: distance
                },
                leadStatus: "partial",
                movingFrom: {
                    address: mf,
                    floor: parseInt(movingFromFloor),
                    liftAvailable: movingFromLift
                },
                movingTo: {
                    address: mt,
                    floor: parseInt(movingToFloor),
                    liftAvailable: movingToLift
                },
            };
            setLead(leadData);
            updateLead(leadData);
            setStep(1);

            await getDistance(lead.customerDetails.movingCity);
        }

    }

    function handleChange(event) {
        setCity(event.target.value)
    }

    function goBack() {
        if (step === 0) {
            closePopUp();
        } else {
            setStep(step - 1);
        }
    }

    const RequirementHeader = (props) => {
        const { title, disbleBack } = props;
        return (
            <div className='requirementHeader'>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ display: (disbleBack ? "none" : "block") }}>
                        <IconButton aria-label="delete" onClick={() => {
                            goBack();
                        }} size="large">
                            <ArrowBackIcon fontSize="inherit" />
                        </IconButton>
                    </div>
                    <div className='requirementHeaderTitle'>
                        <h3 style={{ marginLeft: (disbleBack ? "14px" : "") }}>{title}</h3>
                    </div>
                </div>
                <div>
                    <IconButton aria-label="delete" onClick={() => { closePopUp(); setStep(0); }} size="large">
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </div>

            </div>
        );
    }

    const RequirementFooter = (props) => {

        const { validate } = props;
        return (
            <div className='requirementFooter'>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <div>
                        <IconButton aria-label="go back" onClick={() => { goBack(); }}>
                            <ArrowBackIcon />
                        </IconButton>
                    </div>
                    <div>
                        <Button variant="contained"
                            sx={{
                                color: "#262626",
                                backgroundColor: "#ffcb00",
                                fontSize: "18px",
                                "&:disabled": {
                                    color: "rgb(38 38 38 / 50%)",
                                    backgroundColor: "rgb(255 203 0 / 50%)"
                                },
                                "&:hover": {
                                    color: "#262626",
                                    backgroundColor: "#ffcb00",
                                }
                            }}
                            onClick={() => {
                                validate();
                            }}
                            endIcon={<ArrowForwardIcon />}
                            size="large" disableElevation >Next</Button>
                    </div>
                </div>
            </div>
        );
    }

    const AddressSection = () => {

        const [cityselection, setCityselection] = useState(lead["customerDetails"]["movingCity"]);
        return (
            <>
                <RequirementHeader title={cityselection === "within" ? "Moving in Cities" : "Moving between cities"} />
                <div className='requirementContent'>

                    <ToggleButtonGroup
                        color="primary"
                        fullWidth
                        style={{
                            marginBottom: "20px",
                            width: "100%"
                        }}
                        value={cityselection}
                        exclusive
                        onChange={(event, scope) => {
                            const leadData = {
                                ...lead,
                                customerDetails: {
                                    ...lead.customerDetails,
                                    movingCity: scope,
                                }
                            };
                            setLead(
                                leadData
                            )

                            setCityselection(scope);
                        }}
                    >
                        <ToggleButton selected={cityselection === "within"} value="within">Within City</ToggleButton>
                        <ToggleButton selected={cityselection === "between"} value="between">Between Cities</ToggleButton>
                    </ToggleButtonGroup>

                    {cityselection === "within" ? (

                        <>
                            <InputLabel id="demo-simple-select-label">Select City</InputLabel>
                            <Select
                                fullWidth
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={city}
                                onChange={handleChange}
                            >
                                <MenuItem value="bengaluru">Bangalore</MenuItem>
                                <MenuItem value="hyderabad">Hyderabad</MenuItem>
                                <MenuItem value="chennai">Chennai</MenuItem>
                            </Select>
                        </>
                    ) : ""}

                    <AddressField originalValue={movingFromAddress} idLabel="movingFrom" error={movingFromAddressError} addressCallback={setMovingFromAddress} lable={LABLES.MOVING_FROM} />

                    <div className='floorDivision' style={{
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <div style={{
                            display: "flex",
                            flex: 1,
                        }}>
                            <TextField value={movingFromFloor} inputProps={{ type: 'number' }} label="Enter Floor No" onChange={(event) => setMovingFromFloor(event.target.value)} helperText={movingFromFloorError ? "Enter Floor No. 0 for Ground floor" : ''} error={movingFromFloorError} variant="outlined" />

                        </div>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "center"
                        }}>
                            <div>
                                <FormControlLabel control={<Checkbox checked={movingFromLift} onChange={(event) => setMovingFromLift(event.target.checked)} />} label="Service Lift Available" />
                            </div>
                        </div>
                    </div>

                    <Divider style={{ marginTop: "20px", marginBottom: "10px" }} />


                    <AddressField originalValue={movingToAddress} idLabel="movingTo" error={movingToAddressError} addressCallback={setMovingToAddress} lable={LABLES.MOVING_TO} />


                    <div className='floorDivision'
                        style={{
                            display: "flex",
                            alignItems: "center"
                        }}>
                        <div style={{
                            display: "flex",
                            flex: 1,
                        }}>
                            <TextField inputProps={{ type: 'number' }} value={movingToFloor} label="Enter Floor No" onChange={(event) => setMovingToFloor(event.target.value)} helperText="Enter Floor No" onChange={(event) => setMovingToFloor(event.target.value)} helperText={movingToFloorError ? "Enter Floor No. 0 for Ground floor" : ''} error={movingToFloorError} variant="outlined" />

                        </div>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "center"
                        }}>
                            <div>
                                <FormControlLabel control={<Checkbox checked={movingToLift} onChange={(event) => setMovingToLift(event.target.checked)} />} label="Service Lift Available" />
                            </div>
                        </div>
                    </div>
                    <Divider style={{ marginTop: "30px", marginBottom: "20px" }} />
                    <p>Your quote will be reduced if you have a working service lift in your building.</p>



                </div>

                <RequirementFooter validate={() => validateAddress(cityselection)} />
            </>
        )
    }


    const HouseSizeSelection = () => {






        const validateHouse = () => {

            let houseDetails = {};
            homeSizeData.filter((houset) => {
                houset.subtype.filter((houseSubt) => {
                    if (houseSubt.size === selectedHouse.size) {
                        houseDetails = houseSubt;
                    }
                })
            });

            console.log(houseDetails);

            setLead(
                {
                    ...lead,
                    houseDetails: houseDetails,
                }
            )
            setStep(2);
        };

        return (
            <>
                <RequirementHeader title={"Please choose your house type"} />
                <div className='requirementContent'>
                    <ToggleButtonGroup
                        color="primary"
                        fullWidth
                        style={{
                            marginBottom: "20px",
                            width: "100%"
                        }}
                        value={tabType}
                        exclusive
                        onChange={(event, scope) => {
                            setTabType(scope);
                        }}
                    >

                        {homeSizeData.map((data) => {
                            const key = data.type;
                            return (<ToggleButton key={key} value={data.type}>{data.tabLable}</ToggleButton>);
                        })}

                    </ToggleButtonGroup>




                    {
                        homeSizeData.map((data) => {
                            if (data.type === tabType) {
                                return data.subtype.map((data) => {
                                    return (
                                        <Card variant="outlined" key={data.size} sx={{ minWidth: 275, backgroundColor: "#f5f5f5", marginBottom: "10px" }}>
                                            <CardContent>

                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "space-between"
                                                }}>
                                                    <div>
                                                        <Typography sx={{ fontSize: 16, fontWeight: 500 }} component="div">
                                                            {data.abbreviation}
                                                        </Typography>
                                                        <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                                                            {data.inventory.length} Items Approx.
                                                        </Typography>
                                                    </div>
                                                    <div>

                                                        {data.size === selectedHouse.size ?

                                                            <Button
                                                                size="small"
                                                                style={{
                                                                    color: "#262626",
                                                                    backgroundColor: "#ffcb00",
                                                                    fontSize: "12px"
                                                                }}
                                                                variant="contained"
                                                                disableElevation
                                                            >Selected</Button>
                                                            :

                                                            <Button variant="outlined"
                                                                style={{
                                                                    fontSize: "12px"
                                                                }}
                                                                onClick={() => setSelectedHouse({ size: data.size, abbreviation: data.abbreviation })}
                                                                size="small">
                                                                Select
                                                            </Button>

                                                        }




                                                    </div>
                                                </div>


                                                <Accordion elevation={0}
                                                    sx={{
                                                        "&.MuiAccordion-root:before": {
                                                            backgroundColor: "white"
                                                        },
                                                        marginTop: "15px"
                                                    }}>
                                                    <AccordionSummary
                                                        sx={{
                                                            "&.Mui-expanded": {
                                                                minHeight: "auto"
                                                            },
                                                            ".MuiAccordionSummary-content.Mui-expanded": {
                                                                margin: "12px 0"
                                                            }
                                                        }}
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography sx={{ fontSize: "14px" }}>
                                                            Inventory that can fit in vehicle
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        {
                                                            data.inventory.map((data, index) => {
                                                                return (<Chip key={data['label'] + index}
                                                                    style={{
                                                                        margin: "3px",
                                                                        backgroundColor: "#ffffff"
                                                                    }}
                                                                    avatar={<CheckIcon fontSize="inherit" />}
                                                                    label={data['label']}
                                                                    sx={{ fontSize: 12 }}
                                                                    variant="outlined"
                                                                />);
                                                            })
                                                        }
                                                    </AccordionDetails>
                                                </Accordion>
                                            </CardContent>
                                        </Card>
                                    );

                                });

                            }
                        })
                    }




                    <Divider style={{ marginTop: "30px", marginBottom: "20px" }} />
                    <p>It is a tentative list of what can fit in the vehicle. Additional items can be added if space is available.</p>
                </div>

                <RequirementFooter validate={validateHouse} />
            </>
        )
    }

    const DateSelection = () => {

        console.log(lead);

        const d = new Date();
        d.setDate(d.getDate() + 1);
        const [selectedDate, setSelectedDate] = useState(d.getTime());
        const [value, onChange] = useState(d);
        const [selectedTimeSlot, setSelectedTimeSlot] = useState({ time: "8am10am", lable: "8 AM to 10 AM" });
        const endDate = new Date();
        endDate.setMonth(endDate.getMonth() + 6);

        const onDateChange = (newDate) => {
            setSelectedDate(newDate.getTime());
            onChange(newDate);
        }

        const dateandslotValidate = () => {

            const leadData = {
                ...lead,
                leadStatus: "final",
                movementDate: selectedDate,
                movementSlot: selectedTimeSlot
            };
            setLead(
                leadData
            )
            updateLead(leadData);
            setStep(3);
        }

        return (
            <>
                <RequirementHeader title={"Select your movement date"} />
                <div>
                    <div style={{
                        padding: "20px"
                    }}>
                        <Calendar
                            onChange={onDateChange}
                            showDoubleView={true}
                            value={value}
                            prev2Label={null}
                            next2Label={null}
                            view="month"
                            maxDate={endDate}
                            minDate={d}
                        />
                    </div>

                    <div className='dateSelectionContainer'>
                        <Typography sx={{ fontWeight: 500 }}>
                            Select time slot for Pickup Job
                        </Typography>
                        <div className='dateOptionHolder' style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "20px"
                        }}>
                            {
                                timeSlot.map((data) => {
                                    return (
                                        <div className='dateOptions' key={data.time}>
                                            {
                                                data.time === selectedTimeSlot.time ?
                                                    <Button
                                                        key={data.time}
                                                        size="small"
                                                        style={{
                                                            color: "#262626",
                                                            backgroundColor: "#ffcb00",
                                                            fontSize: "12px"
                                                        }}
                                                        variant="contained"
                                                        disableElevation
                                                    >{data.lable}</Button>

                                                    :

                                                    <Button variant="outlined"
                                                        key={data.time}
                                                        style={{
                                                            fontSize: "12px"
                                                        }}
                                                        onClick={() => setSelectedTimeSlot({ time: data.time, lable: data.lable })}
                                                        size="small">
                                                        {data.lable}
                                                    </Button>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <Divider style={{ marginTop: "30px", marginBottom: "20px" }} />
                        <p>Choose a tentative date that can be updated at any time</p>
                    </div>

                </div>



                <RequirementFooter validate={dateandslotValidate} />
            </>
        );
    }

    const MovementSummary = () => {

        const h3 = {
            fontWeight: "normal",
            fontSize: "16px"
        };
        const h2 = {
            fontSize: "18px"
        };

        const spacer = {
            height: "5px"
        };
        const isWeekend = (() => {
            const day = moment(lead.movementDate).format('dddd');
            if (day === "Saturday") {
                return true;
            } else if (day === "Sunday") {
                return true;
            } else {
                return false;
            }
        })();

        // NOMS API HERE
        let initalCost = isWeekend ? lead.houseDetails.priceWeekEnd : lead.houseDetails.priceWeekDay;
        let estimatedCost = parseInt(initalCost) + 1000;


        const kmRate = 35;


        if (lead.customerDetails.movingCity === "between") {
            estimatedCost = (Math.round(lead.customerDetails.distance) * kmRate) + parseInt(lead.houseDetails.packingCharges) + 1000;
            initalCost = estimatedCost - 1000;
        }



        let advanceAmount = Math.round(parseInt(initalCost) * 15 / 100);

        const triggerPayment = async () => {

            const orderId = await generateOrder(advanceAmount * 100);
            const options = {
                "key": process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
                "amount": advanceAmount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                "currency": "INR",
                "name": "Movesify Packers & Movers",
                "description": lead.houseDetails.abbreviation,
                "image": "https://movesify.com/favicon.png",
                "order_id": orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                "handler": function (response) {
                    const leadData = {
                        ...lead,
                        leadStatus: "advance_paid",
                        payments: {

                            ...lead.payments,
                            advance: {
                                payment_id: response.razorpay_payment_id,
                                order_id: response.razorpay_order_id,
                                signature: response.razorpay_signature,
                                amount: advanceAmount,
                                payment_date: new Date().getTime()
                            }
                        }
                    };
                    setLead(leadData);
                    updateLead(leadData);
                    setStep(4);
                },
                "prefill": {
                    "name": lead.customerDetails.customerName,
                    "email": lead.customerDetails.customerEmailId,
                    "contact": lead.customerDetails.customerMobileNumber
                },
                "notes": {
                    "address": "Movesify Corporate Office"
                },
                "theme": {
                    "color": "#3399cc"
                }
            };
            const rzp1 = window.Razorpay(options);
            rzp1.on('payment.failed', function (response) {
                const error = {
                    "code": response.error.code,
                    "description": response.error.description,
                    "source": response.error.source,
                    "step": response.error.step,
                    "reason": response.error.reason,
                    "order_id": response.error.metadata.order_id,
                    "payment_id": response.error.metadata.payment_id
                }

                const leadData = {
                    ...lead,
                    leadStatus: "payment_failed",
                    payments: {
                        ...lead.payments,
                        advance_payment_failed: error
                    }
                };
                setLead(leadData);
                updateLead(leadData);
            });
            rzp1.open();
        };

        const momentDate = moment(lead.movementDate).format('dddd, MMMM Do YYYY');

        return (
            <>
                <RequirementHeader title={"Your house shifting estimation"} />
                <div className='movementSummaryContainer'>
                    <div>

                        <div className='momentSummarytableContainer'>
                            <div>
                                <div className='tableLabel'>
                                    <h3 style={h3}>Movement Date</h3>
                                </div>
                                <div className='tableContent'>
                                    <h2 style={h2}>{momentDate}</h2>
                                </div>
                            </div>
                            <div>
                                <div className='tableLabel'>
                                    <h3 style={h3}>Movement Slot</h3>
                                </div>
                                <div className='tableContent'>
                                    <h2 style={h2}>{lead.movementSlot.lable}</h2>
                                </div>
                            </div>
                        </div>

                        <div className='momentSummarytableContainer'>
                            <div>
                                <div className='tableLabel'>
                                    <h3 style={h3}>Movement from</h3>
                                </div>
                                <div className='tableContent'>
                                    <h2 style={h2}>{movingFromAddress?.description}</h2>
                                </div>
                            </div>
                            <div>
                                <div className='tableLabel'>
                                    <h3 style={h3}>Lift Available</h3>
                                </div>
                                <div className='tableContent'>
                                    <h2 style={h2}>{lead.movingFrom.liftAvailable ? "Yes" : "No"}</h2>
                                </div>
                            </div>
                        </div>

                        <div className='momentSummarytableContainer'>
                            <div>
                                <div className='tableLabel'>
                                    <h3 style={h3}>Movement to</h3>
                                </div>
                                <div className='tableContent'>
                                    <h2 style={h2}>{movingToAddress?.description}</h2>
                                </div>
                            </div>
                            <div>
                                <div className='tableLabel'>
                                    <h3 style={h3}>Lift Available</h3>
                                </div>
                                <div className='tableContent'>
                                    <h2 style={h2}>{lead.movingTo.liftAvailable ? "Yes" : "No"}</h2>
                                </div>
                            </div>
                        </div>

                        <div className='momentSummarytableContainer'>
                            <div>
                                <div className='tableLabel'>
                                    <h3 style={h3}>Movement Type</h3>
                                </div>
                                <div className='tableContent'>
                                    <h2 style={h2}>{lead.houseDetails.abbreviation}</h2>
                                </div>
                                <div className='tableContent'>
                                    Tentative : {lead.houseDetails.inventory.length} Items
                                </div>
                            </div>
                        </div>


                        <table style={{ width: "100%" }}>
                            <tbody>



                                <tr>
                                    <td colSpan="2"></td>
                                </tr>
                                <tr>
                                    <td colSpan="2" style={{ height: "30px" }}> <hr style={{ border: "1px solid #d4d4d4" }} /></td>
                                </tr>
                                <tr>
                                    <td><h3 style={h3}>Estimated Cost</h3></td>
                                    <td style={{ textAlign: "right" }}><h3 style={h3}>{currency.format(estimatedCost)}</h3></td>
                                </tr>
                                <tr>
                                    <td><h3 style={h3}>Discount (WELCOME) Applied</h3></td>
                                    <td style={{ textAlign: "right" }}><h3 style={h3}>{currency.format(-1000)}</h3></td>
                                </tr>
                                <tr>
                                    <td><h3 style={h3}>Total Movement Cost</h3></td>
                                    <td style={{ textAlign: "right" }}><h2 style={h2}>{currency.format(parseInt(initalCost))}</h2></td>
                                </tr>
                                <tr>
                                    <td><h3 style={h3}>Pay Advance</h3></td>
                                    <td style={{ textAlign: "right" }}><h3 style={h3}>{currency.format(advanceAmount)}</h3></td>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                </div>
                <RequirementFooter validate={triggerPayment} />
            </>
        )
    }



    const PaymentSuccess = () => {

        const h3 = {
            fontSize: "16px"
        };

        const paymentDate = moment(lead.payments.advance.payment_date).format('DD/MM/YYYY, h:mm:ss');
        return (
            <>
                <RequirementHeader disbleBack={true} title={"Thank You, " + lead.customerDetails.customerName} />
                <div className='PaymentSuccess'>
                    <div>
                        <div style={{ textAlign: "center" }}>
                            <CheckCircleOutlineIcon sx={{ color: "#00b549", fontSize: "5rem" }} />
                            <h1 style={{ marginTop: "20px" }}>Payments Received</h1>
                            <h3 style={{ fontWeight: "normal", margin: "20px" }}>We've received your payments of {currency.format(lead.payments.advance.amount)}</h3>
                        </div>
                        <hr style={{ border: "1px solid #d4d4d4" }} />
                        <div style={{ margin: "20px" }}>
                            <h3 style={{ textAlign: "center", margin: "20px" }}>PAYMENT DETAILS</h3>

                            <table style={{ width: "100%" }}>
                                <tbody>

                                    <tr>
                                        <td><h3 style={h3}>Payment Amount</h3></td>
                                        <td style={{ textAlign: "right" }}><h3 style={h3}>{currency.format(lead.payments.advance.amount)}</h3></td>
                                    </tr>
                                    <tr>
                                        <td><h3 style={h3}>Payment ID</h3></td>
                                        <td style={{ textAlign: "right" }}><h3 style={h3}>{lead.payments.advance.payment_id}</h3></td>
                                    </tr>
                                    <tr>
                                        <td><h3 style={h3}>Order ID</h3></td>
                                        <td style={{ textAlign: "right" }}><h3 style={h3}>{lead.payments.advance.order_id}</h3></td>
                                    </tr>
                                    <tr>
                                        <td><h3 style={h3}>Payment Date</h3></td>
                                        <td style={{ textAlign: "right" }}><h3 style={h3}>{paymentDate}</h3></td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <p style={{
                            fontSize: "14px",
                            marginTop: "30px",
                            fontWeight: 100,
                            textAlign: "center",
                            borderTop: "2px solid #d4d4d4",
                            paddingTop: "25px"
                        }}>Our representative will call you shortly. <br />The payment confirmation message is sent to your registered mobile number and email address.</p>

                    </div>

                </div>
            </>
        )
    }

    const renderRightScreen = (step) => {
        switch (step) {
            case 0:
                return <AddressSection />
            case 1:
                return <HouseSizeSelection />
            case 2:
                return <DateSelection />
            case 3:
                return <MovementSummary />
            case 4:
                return <PaymentSuccess />
            default:
                return <AddressSection />
        }
    }


    return (
        <div className='requirementPNM' style={{ display: props.display ? "flex" : "none" }}>
            <div className="wrapper">
                {
                    renderRightScreen(step)
                }
            </div>
        </div>
    );
};


const AddressField = (props) => {

    const { originalValue, lable, addressCallback, idLabel } = props;

    const autocompleteService = new window.google.maps.places.AutocompleteService();
    const [value, setValue] = useState(null);
    const [inputValue, setinputValue] = useState('');
    const [options, setOptions] = useState([]);

    const fetch = useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        [],
    );

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current =
                new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue, componentRestrictions: { country: 'in' }, types: ["establishment", "geocode"], }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }
                setOptions(newOptions);
            }
        });

        if (value) {
        }

        return () => {
            active = false;
        };


    }, [value, inputValue, fetch]);

    return (
        <Autocomplete
            noOptionsText={'Enter your city name for suggessions'}
            id={idLabel}
            sx={{ padding: "12px 0", width: "100%" }}
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.description
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={originalValue}
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                //setValue(newValue);
                addressCallback(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setinputValue(newInputValue);


            }}
            renderInput={(params) => (
                <TextField {...params} label={lable} fullWidth helperText={props.error ? "Choose a proper location or landmark" : ""} error={props.error} />
            )}
            renderOption={(props, option) => {
                const matches = option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [match.offset, match.offset + match.length]),
                );

                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Box
                                    component={LocationOnIcon}
                                    sx={{ color: 'text.secondary', mr: 2 }}
                                />
                            </Grid>
                            <Grid item xs>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            fontWeight: part.highlight ? 700 : 400,
                                        }}
                                    >
                                        {part.text}
                                    </span>
                                ))}

                                <Typography variant="body2" color="text.secondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
};

export default RequirementModalPNM;


/*

<h4 style={{ margin: 0 }}>Any Questions?</h4>
<p style={{ marginTop: "10px", marginBottom: "20px" }}> Reach out to us if you need assistance moving your home. Call us at +91-9686643437</p>



*/