import { BrowserRouter, Routes, Route } from "react-router-dom";

import './App.css';
import './Responsive.css';

import Header from './components/Header';
import Footer from './components/Footer';

import { Outlet, Link } from "react-router-dom";
import HomePage from './pages/HomePage';
import PageNotFound from "./pages/PageNotFound";
import PackersAndMovers from "./pages/PackersAndMovers";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Editor from "./pages/Editor";
import Terms from "./pages/Terms";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Blog from "./pages/Blog";
import Sitemap from "./pages/Sitemap";
import Storage from "./pages/Storage";
import { useParams } from 'react-router-dom';


export default function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<PackersAndMovers />} />
          <Route path="packers-and-movers/:city?/:area?" element={<PackersAndMovers />} />
          <Route path="relocation/:city?/:area?" element={<PackersAndMovers />} />
          <Route path="car-bike-transport-service/:city?/:area?" element={<PackersAndMovers />} />
          <Route path="privacy" element={<PrivacyPolicy />} />
          <Route path="terms-and-conditions" element={<Terms />} />
          <Route path="contact" element={<Contact />} />
          <Route path="storage-services" element={<Storage test="value" />} />
          <Route path="editor" element={<Editor />} />
          <Route path="sitemap/:city?" element={<Sitemap />} />
          <Route path="home" element={<HomePage />} />
          <Route path="blog/:id?" element={<Blog />} />
          <Route path="*" somehting={124} element={<PageNotFound foo="bar" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}


const Layout = (props) => {
  console.log(props);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <link rel="canonical" href={document.location.href} />
        </Helmet>
      </HelmetProvider>
      <Header />
      <div className="bodyContainer">
        <Outlet />
      </div>
      <Footer />
    </>
  )
};

const Contact = () => {
  return (<><p>Contact</p></>);
}
