import logo from '../assets/logo_dark.svg';
import logoLight from '../assets/logo_light.svg';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import whatsAppico from '../assets/whatsapp-animated.gif';

const whiteUrls = ["/storage-services"];

const Header = () => {

    let path = window.location.pathname;


    const [sticky, setSticky] = useState(false);
    const whatsappLink = `https://wa.me/918861776786`;

    const [togglerMenu, setTogglerMenu] = useState(false);


    useEffect(() => {
        function myFunction() {
            if (window.pageYOffset > sticky) {
                header.classList.add("sticky");
                setSticky(true);
            } else {
                header.classList.remove("sticky");
                setSticky(false);
            }
        }


        window.onscroll = function () { myFunction() };

        const header = document.querySelector(".headerContainer");
        const sticky = header.offsetTop;
        // console.log("noms:", whiteUrls.indexOf(path), path, whiteUrls);
        // if (whiteUrls.indexOf(path) < 0) {
        //     console.log("yes initate");

        // } else {
        //     header.classList.add("sticky");
        //     setSticky(true);
        // }




    }, [])

    return (
        <div className='headerContainer'>
            <div className='responsiveIdentifier'></div>
            <header>


                <div className='logoSection'>

                    <div className='handBurgerIcon'>
                        <button aria-label="Menu" onClick={() => { setTogglerMenu(!togglerMenu) }}>
                            <i className="fa fa-bars"></i>
                        </button>
                    </div>


                    <a href='/'>
                        <img src={sticky ? logo : logoLight} alt='Movesify Header Logo' className='logo' />
                    </a>

                    <div className='whatsAppIconResponsive'>
                        <a href={whatsappLink} target="_blank" className="icon" >
                            <i alt="whatsapp us" className="fa-brands fa-whatsapp fa-bounce"></i>
                        </a>

                    </div>
                </div>

                <div className='navigation'>
                    <ul>
                        <li> <a href="/packers-and-movers">Packers & Movers</a></li>
                        <li> <a href="/">Hire Truck</a></li>
                        <li> <a href="/">Cold Logistics</a></li>
                        <li> <a href="/storage-services">Warehouse</a></li>
                        <li>
                            <Button variant="outlined"
                                onClick={() => {
                                    window.open(whatsappLink, '_blank');
                                }}
                                style={{
                                    color: "rgb(38, 38, 38)",
                                    border: "1px solid #ffcb00",
                                    background: "#ffcb00",

                                }} startIcon={<i className="fa-brands fa-whatsapp fa-bounce" ></i>}>
                                +91-8861776786
                            </Button>
                        </li>
                    </ul>

                </div>

                <div className='navigation responsiveNavigation' style={{ display: togglerMenu ? 'block' : 'none' }}>
                    <div>
                        <button className='responsiveMenuClose' onClick={() => { console.log("noms test"); setTogglerMenu(!togglerMenu) }}>
                            <i className="fa fa-xmark"></i>
                        </button>
                    </div>
                    <div>
                        <ul>
                            <li> <a href="/packers-and-movers">Packers & Movers</a></li>
                            <li> <a href="/">Hire Truck</a></li>
                            <li> <a href="/">Cold Logistics</a></li>
                            <li> <a href="/storage-services">Warehouse</a></li>
                            <li> <a href="/blog">Blog</a></li>
                            <li>
                                <Button variant="outlined"
                                    onClick={() => {
                                        window.open(whatsappLink, '_blank');
                                    }}
                                    style={{
                                        color: "rgb(38, 38, 38)",
                                        border: "1px solid #ffcb00",
                                        background: "#ffcb00",

                                    }} startIcon={<i className="fa-brands fa-whatsapp fa-bounce" ></i>}>
                                    +91-8861776786
                                </Button>
                            </li>
                        </ul>
                    </div>

                </div>
            </header >
        </div >
    );
}

export default Header;